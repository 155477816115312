import React, { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classnames from 'classnames';

import './LinkButton.scss';

interface Props extends LinkProps {
  variant?: string;
}

function LinkButton({
  variant = 'secondary',
  className,
  children,
  ...buttonProps
}: PropsWithChildren<Props>) {
  const classNames = classnames('Button', className, {
    [`Button-${variant}`]: variant,
  });

  return (
    <Link className={classNames} {...buttonProps}>
      {children}
    </Link>
  );
}

export default LinkButton;
