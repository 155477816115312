import React from 'react';

interface Props {
  value?: boolean;
}

function YesNo({ value }: Props) {
  return <>{value ? 'YES' : 'NO'}</>;
}

export default YesNo;
