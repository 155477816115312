import { sumBy } from 'lodash';
import { IActivity } from '../graphql/types';
import {
  addSeconds,
  differenceInSeconds,
  intervalToDuration,
  parseISO,
} from 'date-fns';

export function calculateActivityDuration(
  activities?: Pick<IActivity, 'start' | 'end'>[]
) {
  if (!activities) {
    return undefined;
  }

  const totalSeconds = calculateSeconds(activities);

  return calculateDuration(totalSeconds);
}

export function calculateDuration(totalSeconds?: number) {
  if (!totalSeconds) {
    return undefined;
  }

  return intervalToDuration({
    start: new Date(0),
    end: addSeconds(new Date(0), totalSeconds),
  });
}

export function calculateSeconds(
  activities?: Pick<IActivity, 'start' | 'end'>[]
) {
  return sumBy(activities, (activity) => {
    if (!activity) {
      return 0;
    }

    const start = parseISO(activity.start);
    const end = parseISO(activity.end);

    return differenceInSeconds(start, end);
  });
}
