import React from 'react';
import classnames from 'classnames';
import Helmet from 'react-helmet';

import './Page.scss';

interface Props {
  className?: string;
  slug?: string;
  full?: boolean;
}

function Page({
  className,
  slug,
  children,
  full,
}: React.PropsWithChildren<Props>) {
  return (
    <div className={classnames('Page', className, { 'Page-full': full })}>
      <Helmet>{slug && <title>{slug} | Run Things</title>}</Helmet>
      <div className="Page-content">{children}</div>
    </div>
  );
}

export default Page;
