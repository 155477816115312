import React, { useMemo } from 'react';
import qs from 'qs';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { useSearch } from '../../hooks';

interface Props extends LinkProps {
  to: string;
  query?: Record<string, string | number | undefined>;
}

function Link({ to, query, ...props }: Props) {
  const { redirect } = useSearch();

  const path = useMemo(() => {
    const obj = qs.stringify(
      {
        redirect,
        ...query,
      },
      {
        skipNulls: true,
      }
    );

    if (!obj) {
      return to;
    }

    return `${to}?${obj}`;
  }, [to, query, redirect]);

  return <RouterLink to={path} {...props} />;
}

export default Link;
