import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import './SidebarNavigation.scss';

function SidebarNavigation() {
  const items = useMemo(() => {
    return [
      {
        title: 'Dashboards',
        to: '/',
        exact: true,
      },
      {
        title: 'Users',
        to: '/users',
      },
      {
        title: 'Teams',
        to: '/teams',
      },
      {
        title: 'Events',
        to: '/events',
      },
    ];
  }, []);

  return (
    <nav className="Sidebar-section Sidebar-navigation">
      {items.map(({ title, to, exact }) => {
        return (
          <NavLink key={title} to={to} exact={exact}>
            {title}
          </NavLink>
        );
      })}
    </nav>
  );
}

export default SidebarNavigation;
