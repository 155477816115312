import Page from 'components/Page/Page';
import React, { ChangeEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FieldArray, Form, Formik } from 'formik';

import {
  IUpdateEventInput,
  useGetEventQuery,
  useUpdateEventMutation,
} from 'graphql/types';
import { ACTIVITY_TYPES } from 'types/activities';
import { EVENT_TYPES } from 'types/event';

import Button from 'components/Buttons/Button/Button';
import { TextInputFormik } from 'components/Form/TextInput/TextInput';
import { SelectInputFormik } from 'components/Form/SelectInput/SelectInput';
import Table from 'components/Table/Table';
import Bin from 'components/Icons/Bin';
import Loader from 'components/Loader/Loader';

import './EditEventPage.scss';
import { getEventEditForm } from 'components/Events/EventTypes';

function EditEventPage() {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetEventQuery({ variables: { id } });
  const [updateEvent] = useUpdateEventMutation();
  const { push } = useHistory();

  const event = data?.event;

  if (loading) {
    return <Loader />;
  }

  if (!event) {
    return <div>No event found</div>;
  }

  async function onSave(values: IUpdateEventInput) {
    console.log(values);

    const { data } = await updateEvent({
      variables: {
        input: {
          ...values,
          // This is done instead of casting to a date to avoid timezone issues with the users browser. Not ideal
          start: `${values.start}:00.000Z`,
          end: `${values.end}:00.000Z`,
        },
      },
    });

    if (data) {
      push(`/events/${data.event.id}`);
    }
  }

  const EventEditForm = getEventEditForm(event.type);

  return (
    <Page className="EditEventPage" slug="Edit Event">
      <EventEditForm event={event} onSave={onSave} />
    </Page>
  );
}

function CreateEditPageWrapper() {}

export default EditEventPage;
