import React from 'react';
import { useGetEventStatisticsQuery } from 'graphql/types';
import { useParams } from 'react-router-dom';

import Loader from 'components/Loader/Loader';
import DataSection from 'components/DataSection/DataSection';
import DataSectionRow from 'components/DataSection/DataSectionRow';
import Distance from '../../../../components/Distance/Distance';
import Duration from 'components/Duration/Duration';

function EventStats() {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetEventStatisticsQuery({ variables: { id } });

  if (loading) {
    return <Loader />;
  }

  if (!data?.statistics) {
    return <Loader />;
  }

  const activities = data.statistics.activities;

  return (
    <div className="EventDetails">
      {activities.map((activity) => (
        <DataSection title={activity.name}>
          <DataSectionRow label="Total">{activity.total || 0}</DataSectionRow>
          <DataSectionRow label="Total Distance">
            <Distance metres={activity.totalDistance || 0} />
          </DataSectionRow>
          <DataSectionRow label="Average Distance">
            <Distance metres={activity.averageDistance || 0} />
          </DataSectionRow>
          <DataSectionRow label="Total Time">
            <Duration
              seconds={activity.totalSeconds || 0}
              defaultValue={'0d 0h 0m 0s'}
            />
          </DataSectionRow>
          <DataSectionRow label="Average Time">
            <Duration
              seconds={activity.averageSeconds || 0}
              defaultValue={'0d 0h 0m 0s'}
            />
          </DataSectionRow>
          <DataSectionRow label="Longest">
            <Distance metres={activity.longest || 0} />
          </DataSectionRow>
        </DataSection>
      ))}
    </div>
  );
}

export default EventStats;
