import { useGetProfileQuery } from '../graphql/types';

export function useProfile() {
  const { data, refetch } = useGetProfileQuery();

  return {
    profile: data?.profile,
    refetch,
  };
}
