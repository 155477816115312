import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UserMessage from './UserMessage';
import UserMessages from './UserMessages';

function UserMessagesRoute() {
  return (
    <Switch>
      <Route path={`/users/:userId/messages/:id`} component={UserMessage} />
      <Route path={`/users/:id/messages`} component={UserMessages} />
    </Switch>
  );
}

export default UserMessagesRoute;
