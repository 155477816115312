import React from 'react';

import Panel from 'components/Panel/Panel';

import './Table.scss';

interface Props {
  columns: string[];
  template?: string;
}

function Table({
  columns,
  template,
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <Panel>
      <table
        className="Table"
        style={{
          gridTemplateColumns: template
            ? template
            : columns.map(() => 'minmax(90px, auto)').join(' '),
        }}
      >
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>
                <div>{column}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </Panel>
  );
}

export default Table;
