import React from 'react';
import Button from '../../../../components/Buttons/Button/Button';
import { useLoginMutation } from '../../../../graphql/types';
import { useAuth } from '../../../../providers/AuthProvider';
import configuration from '../../../../configuration';

interface Props {
  id: string;
}

function UserActions({ id }: Props) {
  const [loginAs] = useLoginMutation();
  const { setToken } = useAuth();

  async function login() {
    const { data, errors } = await loginAs({
      variables: {
        id,
      },
    });

    if (errors && errors[0]) {
      alert(`Something went wrong: ${errors[0].message}`);
      return;
    }

    const token = data?.token;

    if (!token) {
      alert(`Something went wrong`);
      return;
    }

    setToken(token);
    window.location.href = configuration.loginUrl;
  }

  return (
    <>
      <Button>Unlock</Button>
      <Button onClick={login}>Login</Button>
    </>
  );
}

export default UserActions;
