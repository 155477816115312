import Page from 'components/Page/Page';
import React from 'react';

import './CreateTeamPage.scss';
import Button from 'components/Buttons/Button/Button';
import { TextInputFormik } from 'components/Form/TextInput/TextInput';
import { Form, Formik } from 'formik';

function CreateTeamPage() {
  return (
    <Page className="TeamPage">
      <div className="Page-title">
        Create team
        <Button variant="primary">Save</Button>
      </div>
      <hr />
      <Formik
        initialValues={{
          name: '',
          timezone: 'Europe/London',
          distance: 0,
          entries: 0,
        }}
        onSubmit={console.log}
      >
        <Form className="Form">
          <div className="Form-group">
            <TextInputFormik label="Team name" name="name" />
            <TextInputFormik label="Distance" type="number" name="distance" />
          </div>
          <div className="Form-group">
            <TextInputFormik label="Entries" type="number" name="entries" />
            <TextInputFormik label="Timezone" name="timezone" />
          </div>
        </Form>
      </Formik>
    </Page>
  );
}

export default CreateTeamPage;
