import React, { ChangeEvent } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { TextInputFormik } from 'components/Form/TextInput/TextInput';
import FileInput from 'components/Form/FileInput/FileInput';
import Table from 'components/Table/Table';
import Button from 'components/Buttons/Button/Button';
import Bin from 'components/Icons/Bin';
import { ACTIVITY_TYPES } from 'types/activities';
import { ICreateEventInput } from 'graphql/types';
import { Field } from 'formik';

function EventFields() {
  const { values, setValues } = useFormikContext<ICreateEventInput>();

  return (
    <>
      <TextInputFormik label="Name" name="name" required />
      <TextInputFormik label="Description" name="description.en" required />
      <div className="Form-group">
        <TextInputFormik
          label="Start"
          type="datetime-local"
          name="start"
          required
        />
        <TextInputFormik
          label="End"
          type="datetime-local"
          name="end"
          required
        />
      </div>
      <TextInputFormik
        label="Marketing ID"
        name="config.marketingId"
        required
      />

      <>
        <h3>Images</h3>
        <div className="Form-group">
          <FileInput label="Header" name="config.header" />
          <FileInput label="Thumbnail" name="config.thumbnail" />
        </div>
      </>

      <>
        <FieldArray name="groups">
          {(helpers) => {
            function onDelete(index: number) {
              helpers.remove(index);
            }

            function onAdd(index: number) {
              helpers.insert(index, {
                name: '',
                description: '',
                target: 0,
              });
            }

            const columns = ['Name', 'Description', 'Target', ''];

            return (
              <>
                <h3 className="Spaced">
                  <span>Groups</span>
                  <Button onClick={() => onAdd(0)} type="button">
                    Add
                  </Button>
                </h3>
                <Table
                  columns={columns}
                  template={`repeat(${
                    columns.length - 1
                  }, minmax(90px, auto)) min-content`}
                >
                  {values.groups.map((group, index) => (
                    <tr key={index}>
                      <td>
                        <div>
                          <TextInputFormik name={['groups', index, 'name']} />
                        </div>
                      </td>
                      <td>
                        <div>
                          <TextInputFormik
                            name={['groups', index, 'description']}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <TextInputFormik
                            name={['groups', index, 'target']}
                            type="number"
                          />
                        </div>
                      </td>
                      <td className="Text-right">
                        <div>
                          <Button
                            className="Button-icon"
                            onClick={() => onDelete(index)}
                            type="button"
                          >
                            <div className="Button-icon-wrapper">
                              <Bin />
                            </div>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </Table>
              </>
            );
          }}
        </FieldArray>
      </>

      <FieldArray name="config.distances">
        {(helpers) => {
          function onDelete(index: number): void {
            helpers.remove(index);
          }
          function onAdd(index: number): void {
            helpers.insert(index, 0);
          }

          const columns = ['index', 'target', ''];

          return (
            <div className="">
              <h3 className="Spaced">
                <span>Distances</span>
                <Button onClick={() => onAdd(0)} type="button">
                  Add
                </Button>
              </h3>
              <Table
                columns={columns}
                template={`repeat(${
                  columns.length - 1
                }, minmax(90px, auto)) min-content`}
              >
                {values.config.distances &&
                  values.config.distances.map((distance, index) => (
                    <tr className="flex align-center" key={index}>
                      <td>
                        <div>{index + 1}</div>
                      </td>
                      <td>
                        <div>
                          <TextInputFormik
                            type="number"
                            name={['config', 'distances', index]}
                          />
                        </div>
                      </td>
                      <td className="Text-right">
                        <div>
                          <Button
                            className="Button-icon"
                            onClick={() => onDelete(index)}
                            type="button"
                          >
                            <div className="Button-icon-wrapper">
                              <Bin />
                            </div>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </Table>
            </div>
          );
        }}
      </FieldArray>
      <h3>Mandatory Evidence</h3>
      <label>
        <Field
          style={{ marginRight: '0.2rem' }}
          type="checkbox"
          name="config.mandatoryEvidence"
        />
        <span style={{ userSelect: 'none' }}>
          Make evidence a mandatory field
        </span>
      </label>

      <>
        <h3 className="Spaced">
          <span>Activities</span>
          <div>
            <Button
              type="button"
              onClick={() => {
                values.config.activities = ACTIVITY_TYPES;
                setValues(values);
              }}
            >
              Select All
            </Button>{' '}
            <Button
              type="button"
              onClick={() => {
                values.config.activities = [];
                setValues(values);
              }}
            >
              De-select All
            </Button>
          </div>
        </h3>
        <FieldArray name="activities">
          {() => {
            return (
              <>
                {ACTIVITY_TYPES.map((type) => {
                  function onChange(event: ChangeEvent<HTMLInputElement>) {
                    if (event.target.checked) {
                      values.config.activities.push(type);

                      setValues(values);
                    } else {
                      values.config.activities =
                        values.config.activities.filter((t) => t !== type);

                      setValues(values);
                    }
                  }

                  return (
                    <div key={type}>
                      <label>
                        <input
                          type="checkbox"
                          checked={values.config.activities.includes(type)}
                          onChange={onChange}
                        />
                        <span> {type}</span>
                      </label>
                    </div>
                  );
                })}
              </>
            );
          }}
        </FieldArray>
      </>
    </>
  );
}

export default EventFields;
