import React from 'react';
import { IUnit, usePreferences } from 'providers/PreferencesProvider';

interface Props {
  metres?: number;
  hideUnits?: boolean;
  digits?: number;
  space?: boolean;
}

function Distance({
  metres,
  hideUnits = false,
  digits = 1,
  space = false,
}: Props) {
  const { unit } = usePreferences();

  const distance = convertTo(metres, unit).toLocaleString(undefined, {
    minimumFractionDigits: digits,
  });

  if (hideUnits) {
    return <>{distance}</>;
  }

  return (
    <>
      <span className="Distance-value">{distance}</span>
      {space ? ' ' : ''}
      <span className="Distance-unit">{unit.toUpperCase()}</span>
    </>
  );
}

export function convertTo(metres: number | undefined, unit: IUnit) {
  if (!metres) {
    return 0;
  }

  const km = metres / 1000;

  if (unit === 'km') {
    return km;
  }

  return km / 1.60934;
}

export default Distance;
