import React from 'react';

interface Props {
  label: string;
  flat?: boolean;
}

function DataSectionRow({
  label,
  children,
  flat,
}: React.PropsWithChildren<Props>) {
  return (
    <div className="DataSection-row">
      <div className="DataSection-key">{label}</div>
      <div className="DataSection-value">
        <pre className={flat ? 'flat' : undefined}>{children}</pre>
      </div>
    </div>
  );
}

export default DataSectionRow;
