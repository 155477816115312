import React from 'react';

import SidebarNavigation from './SidebarNavigation/SidebarNavigation';
import { usePreferences } from 'providers/PreferencesProvider';

import { useProfile } from 'hooks';
import './Sidebar.scss';
import { useAuth } from '../../providers/AuthProvider';

function Sidebar() {
  const { unit, setUnit } = usePreferences();
  const { profile } = useProfile();
  const { user } = useAuth();

  return (
    <div className="Sidebar">
      <SidebarNavigation />
    </div>
  );
}

export default Sidebar;
