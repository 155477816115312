const config = (window as any).configuration ?? {};

const env: Record<string, string | undefined> = {
  REACT_APP_URL: process.env.REACT_APP_URL,
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
  REACT_APP_MARKETING_SITE_URL: process.env.REACT_APP_MARKETING_SITE_URL,
  REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE:
    process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE,
  REACT_APP_LOGIN_URL: process.env.REACT_APP_LOGIN_URL,
};

function get(name: string): string {
  return config[name] || env[name];
}

const configuration = {
  environment: get('REACT_APP_ENVIRONMENT'),
  version: get('REACT_APP_VERSION'),
  apiUrl: get('REACT_APP_API_URL'),
  loginUrl: get('REACT_APP_LOGIN_URL'),
  cookieDomain: get('REACT_APP_COOKIE_DOMAIN'),
  marketingSiteUrl: get('REACT_APP_MARKETING_SITE_URL'),
  googleAnalyticsTrackingCode: get('REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE'),
  sentry: {
    dsn: get('REACT_APP_SENTRY_DSN'),
  },
};

export default configuration;
