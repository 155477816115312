import React from 'react';
import { useGetEventQuery } from 'graphql/types';
import { useParams } from 'react-router-dom';

import Loader from 'components/Loader/Loader';
import { getEventDetails } from 'components/Events/EventTypes';

function EventDetails() {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetEventQuery({ variables: { id } });

  const event = data?.event;

  if (loading) {
    return <Loader />;
  }

  if (!event) {
    return <div>No event found</div>;
  }

  const EventDetails = getEventDetails(event.type);

  return <EventDetails event={event} />;
}

export default EventDetails;
