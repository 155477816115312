import React from 'react';

import Link from 'components/Router/Link';
import { useGetUserTeamsQuery } from 'graphql/types';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader/Loader';
import Table from 'components/Table/Table';
import { orderBy } from 'lodash';

function UserTeams() {
  const { id } = useParams<{ id: string }>();
  const columns = ['Name', 'Event', 'Status', 'Admin'];
  const { data, loading } = useGetUserTeamsQuery({ variables: { id } });

  if (loading) {
    return <Loader />;
  }

  const teams = orderBy(data?.teams, 'name') ?? [];

  return (
    <Table columns={columns}>
      {teams.map((team) => (
        <tr key={team.id}>
          <td>
            <Link to={`/teams/${team.id}`}>{team.name}</Link>
          </td>
          <td>
            <div>{team.event.name}</div>
          </td>
          <td>
            <div>{team.membership?.status}</div>
          </td>
          <td>
            <div>{team.membership?.isAdmin ? 'YES' : 'NO'}</div>
          </td>
        </tr>
      ))}
    </Table>
  );
}

export default UserTeams;
