import React from 'react';
import { useGetUserDetailsQuery } from 'graphql/types';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader/Loader';
import DataSection from '../../../../components/DataSection/DataSection';
import DataSectionRow from '../../../../components/DataSection/DataSectionRow';

function UserDetails() {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetUserDetailsQuery({ variables: { id } });

  const user = data?.user;

  if (loading) {
    return <Loader />;
  }

  if (!user) {
    return <div>No user found</div>;
  }

  const { firstName, lastName, email, username } = user;

  return (
    <div className="UserDetails">
      <DataSection title="Profile">
        <DataSectionRow label="ID">{id}</DataSectionRow>
        <DataSectionRow label="First Name">{firstName}</DataSectionRow>
        <DataSectionRow label="Last Name">{lastName}</DataSectionRow>
        <DataSectionRow label="Email">{email}</DataSectionRow>
        <DataSectionRow label="Username">{username}</DataSectionRow>
      </DataSection>
    </div>
  );
}

export default UserDetails;
