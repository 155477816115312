import React from 'react';
import { useGetTeamQuery } from 'graphql/types';
import { useParams } from 'react-router-dom';

import Loader from 'components/Loader/Loader';
import Distance from 'components/Distance/Distance';
import DataSection from '../../../../components/DataSection/DataSection';
import DataSectionRow from '../../../../components/DataSection/DataSectionRow';
import YesNo from '../../../../components/YesNo/YesNo';

function TeamDetails() {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetTeamQuery({ variables: { id } });

  const team = data?.team;

  if (loading) {
    return <Loader />;
  }

  if (!team) {
    return <div>No team found</div>;
  }

  const { name, isPrivate, event, target } = team;
  const settings = team.settings!;

  return (
    <div className="TeamDetails">
      <DataSection title="Overview">
        <DataSectionRow label="ID">{id}</DataSectionRow>
        <DataSectionRow label="Name">{name}</DataSectionRow>
        <DataSectionRow label="Event">{event.name}</DataSectionRow>
        <DataSectionRow label="Target">
          <Distance metres={target} digits={0} />
        </DataSectionRow>
      </DataSection>
      <DataSection title="Settings">
        <DataSectionRow label="Invite Url">{settings.inviteUrl}</DataSectionRow>
        <DataSectionRow label="Approval Required">
          <YesNo value={settings.approvalRequired} />
        </DataSectionRow>
        <DataSectionRow label="Code">{settings.code}</DataSectionRow>
        <DataSectionRow label="Private">
          <YesNo value={isPrivate} />
        </DataSectionRow>
      </DataSection>
      <DataSection title="Entries">
        <DataSectionRow label="Total">{settings.entries}</DataSectionRow>
        <DataSectionRow label="Remaining">
          {settings.remainingEntries}
        </DataSectionRow>
      </DataSection>
    </div>
  );
}

export default TeamDetails;
