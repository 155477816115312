import Page from 'components/Page/Page';
import React from 'react';

import './UserPage.scss';

import { NavLink, Route, useParams } from 'react-router-dom';
import UserTeams from './UserTeams/UserTeams';
import UserEvents from './UserEvents/UserEvents';
import UserActivities from './UserActivities/UserActivities';
import { useGetUserDetailsQuery } from '../../../graphql/types';
import UserProviders from './UserProviders/UserProviders';
import UserAddresses from './UserAddresses/UserAddresses';
import UserDetails from './UserDetails/UserDetails';
import Loader from '../../../components/Loader/Loader';
import UserActions from './UserActions/UserActions';
import UserMessagesRoute from './UserMessages/UserMessagesRoute';

function UserPage() {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetUserDetailsQuery({ variables: { id } });

  const user = data?.user;

  if (loading) {
    return <Loader />;
  }

  if (!user) {
    return <div>No user found</div>;
  }

  return (
    <Page className="UserPage">
      <div className="Page-header">
        <div>
          <h3>
            {user.firstName} {user.lastName}
          </h3>
          <span>{user.id}</span>
        </div>

        <div className="Page-header-actions">
          <UserActions id={user.id} />
        </div>

        <nav className="Page-header-nav">
          <NavLink to={`/users/${id}`} exact>
            Details
          </NavLink>
          <NavLink to={`/users/${id}/events`}>Events</NavLink>
          <NavLink to={`/users/${id}/teams`}>Teams</NavLink>
          <NavLink to={`/users/${id}/activities`}>Activities</NavLink>
          <NavLink to={`/users/${id}/providers`}>Providers</NavLink>
          <NavLink to={`/users/${id}/addresses`}>Addresses</NavLink>
          <NavLink to={`/users/${id}/messages`}>Messages</NavLink>
        </nav>
      </div>
      <div className="Page-contents">
        <Route path={`/users/:id`} exact component={UserDetails} />
        <Route path={`/users/:id/teams`} component={UserTeams} />
        <Route path={`/users/:id/events`} component={UserEvents} />
        <Route path={`/users/:id/activities`} component={UserActivities} />
        <Route path={`/users/:id/providers`} component={UserProviders} />
        <Route path={`/users/:id/addresses`} component={UserAddresses} />
        <Route path={`/users/:id/messages`} component={UserMessagesRoute} />
      </div>
    </Page>
  );
}

export default UserPage;
