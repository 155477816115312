import Page from 'components/Page/Page';
import React, { useEffect, useMemo, useState } from 'react';

import './TeamsPage.scss';
import { ITeamOverviewFragment, useGetTeamsLazyQuery } from 'graphql/types';
import _ from 'lodash';
import Link from 'components/Router/Link';
import Table from '../../../components/Table/Table';
import Distance from '../../../components/Distance/Distance';
import LinkButton from 'components/Buttons/LinkButton/LinkButton';
import Search from '../../../components/Icons/Search';

function TeamsPage() {
  const [getTeams, { data }] = useGetTeamsLazyQuery();
  const [teams, setTeams] = useState<ITeamOverviewFragment[]>([]);

  const search = useMemo(() => {
    const throttle = _.debounce(async (filter: string) => {
      console.log({ filter });

      return getTeams({
        variables: { filter, take: 10 },
      });
    }, 250);

    return throttle;
  }, []);

  useEffect(() => {
    if (data?.teams) {
      setTeams(data.teams);
    }
  }, [data?.teams]);

  useEffect(() => {
    search('');
  }, []);

  const columns = ['Name', 'Event', 'Target'];

  return (
    <Page slug="Teams">
      <div className="Page-title">
        Teams
        <div>
          <LinkButton variant="primary" to="/teams/create">
            Create a team
          </LinkButton>
        </div>
      </div>
      <div>
        <div className="Search">
          <label>
            <Search />
          </label>
          <input
            onChange={(e) => search(e.target.value)}
            placeholder="Search by ID or name."
          />
        </div>
      </div>

      <Table columns={columns}>
        {teams.map((team) => (
          <tr key={team.id}>
            <td>
              <Link to={`/teams/${team.id}`}>{team.name}</Link>
            </td>
            <td>
              <div>{team.event.name}</div>
            </td>
            <td>
              <div>
                <Distance metres={team.target} digits={0} />
              </div>
            </td>
          </tr>
        ))}
      </Table>
    </Page>
  );
}

export default TeamsPage;
