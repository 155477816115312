import React from 'react';

import './DataSection.scss';

interface Props {
  title?: string;
}

function DataSection({ title, children }: React.PropsWithChildren<Props>) {
  return (
    <div className="DataSection">
      {title && (
        <div className="DataSection-header">
          <h3>{title}</h3>
        </div>
      )}

      <div className="DataSection-contents">{children}</div>
    </div>
  );
}

export default DataSection;
