import React from 'react';

import { useGetUserMessagesQuery } from '../../../../graphql/types';
import { useParams } from 'react-router-dom';
import DataSection from '../../../../components/DataSection/DataSection';
import DataSectionRow from '../../../../components/DataSection/DataSectionRow';
import Loader from '../../../../components/Loader/Loader';
import DateFormat from '../../../../components/DateFormat/DateFormat';

function UserMessage() {
  const { id, userId } = useParams<{ id: string; userId: string }>();
  const { data, loading } = useGetUserMessagesQuery({
    variables: { id: userId },
  });

  if (loading) {
    return <Loader />;
  }

  const message = data?.messages.find((message) => message.id === id);

  if (!message) {
    return null;
  }

  const { subject, text, type, sent } = message;

  return (
    <div className="UserDetails">
      <DataSection title="Details">
        <DataSectionRow label="Subject">{subject}</DataSectionRow>
        <DataSectionRow label="Sent">
          <DateFormat value={sent} format="dd/MM/yyyy HH:mm" />
        </DataSectionRow>
        <DataSectionRow label="Type">{type}</DataSectionRow>
        <DataSectionRow label="Text" flat>
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </DataSectionRow>
      </DataSection>
    </div>
  );
}

export default UserMessage;
