import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EventPage from './EventPage/EventPage';
import EventsPage from './EventsPage/EventsPage';
import CreateEventPage from './CreateEventPage/CreateEventPage';
import EditEventPage from './EditEventPage/EditEventPage';

function EventsRoute() {
  return (
    <Switch>
      <Route path="/events" exact component={EventsPage} />
      <Route path="/events/create" exact component={CreateEventPage} />
      <Route path="/events/:id/edit" component={EditEventPage} />
      <Route path="/events/:id" component={EventPage} />
    </Switch>
  );
}

export default EventsRoute;
