import React from 'react';

import Link from 'components/Router/Link';
import Distance from 'components/Distance/Distance';
import Table from 'components/Table/Table';
import { useParams } from 'react-router-dom';
import { useGetUserActivitiesQuery } from '../../../../graphql/types';
import Loader from '../../../../components/Loader/Loader';
import DateFormat from '../../../../components/DateFormat/DateFormat';
import YesNo from '../../../../components/YesNo/YesNo';
import { orderBy } from 'lodash';

function UserActivities() {
  const columns = [
    'Description',
    'Distance',
    'Start',
    'End',
    'Source',
    'Manual',
  ];
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetUserActivitiesQuery({ variables: { id } });

  if (loading) {
    return <Loader />;
  }

  const activities = orderBy(data?.activities, 'start', ['desc']) ?? [];

  return (
    <Table columns={columns}>
      {activities.map((activity: any) => (
        <tr key={activity.id}>
          <td>
            <Link to={`/activities/${activity.id}`}>
              {activity.description}
            </Link>
          </td>
          <td>
            <div>
              <Distance metres={activity.distance} />
            </div>
          </td>
          <td>
            <div>
              <DateFormat value={activity.start} format="dd/MM/yy HH:mm" />
            </div>
          </td>
          <td>
            <div>
              <DateFormat value={activity.end} format="dd/MM/yy HH:mm" />
            </div>
          </td>
          <td>
            <div>{activity.source}</div>
          </td>
          <td>
            <div>
              <YesNo value={activity.manual} />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
}

export default UserActivities;
