import React from 'react';
import { ICreateEventInput } from 'graphql/types';
import { TextInputFormik } from 'components/Form/TextInput/TextInput';
import { Form, Formik } from 'formik';
import Button from 'components/Buttons/Button/Button';
import { EventEditProps } from '../EventTypes';
import EventFields from '../Shared/EventFields';

export default function TimeboxedEventEdit({
  event,
  onSave,
  beforeForm,
}: EventEditProps) {
  const initialValues = {
    id: event.id,
    name: event.name,
    description: event.description,
    type: event.type,
    // This is done instead of casting to a date to avoid timezone issues with the users browser. Not ideal
    start: event.start.substring(0, event.start.length - ':00.000Z'.length),
    end: event.end.substring(0, event.start.length - ':00.000Z'.length),
    config: {
      marketingId: event.settings.marketingId,
      thumbnail: event.settings.thumbnail,
      header: event.settings.header,
      activities: event.settings.activities,
      timebox: {
        duration: event.settings.timebox?.duration!,
        distance: event.settings.timebox?.distance!,
      },
      mandatoryEvidence: event.settings.mandatoryEvidence,
    },
    groups: event.groups.map((group) => ({
      id: group.id,
      name: group.name,
      description: group.description,
      target: group.target,
    })),
  };

  return (
    <Formik<ICreateEventInput> initialValues={initialValues} onSubmit={onSave}>
      {({ values, setValues }) => (
        <Form className="Form">
          <div className="Page-title">
            Edit {event.name}
            <Button variant="primary">Save</Button>
          </div>
          <hr />

          {beforeForm && beforeForm}

          <EventFields />

          <>
            <h3>Timeboxed event settings</h3>
            <div className="Form-group">
              <TextInputFormik
                label="Timebox duration (hours)"
                name="config.timebox.duration"
                type="number"
                required
              />
              <TextInputFormik
                label="Timeboxed distance (metres)"
                name="config.timebox.distance"
                type="number"
                required
              />
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
}
