import React from 'react';
import { IEventDetailsFragment } from 'graphql/types';
import DataSection from '../../../components/DataSection/DataSection';
import DataSectionRow from '../../../components/DataSection/DataSectionRow';
import DateFormat from '../../../components/DateFormat/DateFormat';

interface Props {
  event: IEventDetailsFragment;
}

export default function EventDetails({
  event,
  children,
}: React.PropsWithChildren<Props>) {
  const { id, name, description, type, start, end } = event;

  return (
    <DataSection title="Overview">
      <DataSectionRow label="ID">{id}</DataSectionRow>
      <DataSectionRow label="Name">{name}</DataSectionRow>
      <DataSectionRow label="Description">{description.en}</DataSectionRow>
      <DataSectionRow label="Type">{type}</DataSectionRow>
      <DataSectionRow label="Start">
        <DateFormat value={start} format="dd/MM/yyyy HH:mm" />
      </DataSectionRow>
      <DataSectionRow label="End">
        <DateFormat value={end} format="dd/MM/yyyy HH:mm" />
      </DataSectionRow>

      {children}
    </DataSection>
  );
}
