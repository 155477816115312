import Page from 'components/Page/Page';
import React from 'react';

import './TeamPage.scss';

import { NavLink, Route, useParams } from 'react-router-dom';
import { useGetTeamQuery } from '../../../graphql/types';
import Loader from 'components/Loader/Loader';
import TeamDetails from './TeamDetails/TeamDetails';
import TeamMembers from './TeamMembers/TeamMembers';

function TeamPage() {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetTeamQuery({ variables: { id } });

  const team = data?.team;

  if (loading) {
    return <Loader />;
  }

  if (!team) {
    return <div>No team found</div>;
  }

  return (
    <Page className="TeamPage">
      <div className="Page-header">
        <div>
          <h3>{team.name}</h3>
          <span>{team.id}</span>
        </div>

        <nav className="Page-header-nav">
          <NavLink to={`/teams/${id}`} exact>
            Details
          </NavLink>
          <NavLink to={`/teams/${id}/members`}>Members</NavLink>
        </nav>
      </div>
      <div className="Page-contents">
        <Route path={`/teams/:id`} exact component={TeamDetails} />
        <Route path={`/teams/:id/members`} component={TeamMembers} />
      </div>
    </Page>
  );
}

export default TeamPage;
