import React, { useMemo } from 'react';
import { format, parseISO } from 'date-fns';

interface Props {
  value: number | string | Date;
  format: string;
}

function DateFormat({ value, format: formatString }: Props) {
  const date = useMemo(() => {
    if (typeof value === 'string') {
      return parseISO(value);
    }

    return value;
  }, [value]);

  return <>{format(date, formatString)}</>;
}

export default DateFormat;
