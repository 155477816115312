import React, { useState } from 'react';

export interface ISearchContext {
  active: boolean;
  close(): void;
  open(): void;
}

export const SearchContext = React.createContext<ISearchContext>({
  active: false,
  close() {},
  open() {},
});

export const useSearchBox = () => React.useContext(SearchContext);

function SearchProvider({ children }: React.PropsWithChildren<unknown>) {
  const [active, setActive] = useState(false);

  function close() {
    setActive(false);
  }

  function open() {
    setActive(true);
  }

  return (
    <SearchContext.Provider
      value={{
        active,
        close,
        open,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export default SearchProvider;
