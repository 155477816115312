import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { addDays } from 'date-fns';
import { IToken } from '../graphql/types';
import configuration from '../configuration';

export interface IAuthContext {
  loading: boolean;
  isAuthenticated?: boolean;
  user: any;
  login(redirect?: string): void;
  logout(): void;
  getAccessTokenSilently(): Promise<string | undefined>;
  handleCallback(provider: string): (response: any) => void;
  setToken(token: IToken): void;
  clearToken(): void;
}

const AuthContext = React.createContext<IAuthContext>({
  isAuthenticated: undefined,
  loading: true,
  login() {},
  logout() {},
  user: {},
  async setToken() {},
  async clearToken() {},
  async getAccessTokenSilently() {
    return undefined;
  },
  handleCallback(): (response: any) => void {
    return () => {};
  },
});

export const useAuth = () => React.useContext(AuthContext);

interface Props {
  domain: string;
  redirectUri: string;
  logoutUrl: string;
}

function AuthProvider({
  domain,
  redirectUri,
  logoutUrl,
  children,
}: React.PropsWithChildren<Props>) {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [user, setUser] = useState<any>();
  const [cookies, setCookie, removeCookie] = useCookies(['access-token']);

  async function initialize() {
    const { accessToken, idToken } = cookies['access-token'] ?? {};

    setUser(parsePayload(idToken));
    setIsAuthenticated(!!accessToken);
    setLoading(false);
  }

  useEffect(() => {
    initialize().then();
    // eslint-disable-next-line
  }, []);

  async function login(provider: string, redirect?: string) {
    window.location.href = `${
      configuration.loginUrl
    }/login?redirect=${encodeURIComponent(`${redirectUri}/${redirect ?? ''}`)}`;
  }

  async function getAccessTokenSilently() {
    const { accessToken } = cookies['access-token'] ?? {};

    return accessToken;
  }

  function handleCallback(provider: string) {
    return async (response: any) => {};
  }

  function logout() {
    clearToken();
    window.location.href = logoutUrl;
  }

  function setToken(token: IToken) {
    const { accessToken, expiresIn, idToken } = token;

    setCookie(
      'access-token',
      { accessToken, expiresIn, idToken },
      {
        expires: addDays(new Date(), 1),
        path: '/',
        domain: configuration.cookieDomain,
      }
    );

    setUser(parsePayload(idToken));
    setIsAuthenticated(true);
  }

  function clearToken() {
    removeCookie('access-token', {
      path: '/',
      domain: configuration.cookieDomain,
    });
    setUser(undefined);
    setIsAuthenticated(false);
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        loading,
        isAuthenticated,
        getAccessTokenSilently,
        user,
        handleCallback,
        logout,
        setToken,
        clearToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function parsePayload(idToken?: string | null) {
  if (!idToken) {
    return null;
  }

  try {
    const [, payloadString] = idToken.split('.');
    const base64 = payloadString.replace(/-/g, '+').replace(/_/g, '/');
    const payload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((char) => {
          return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(payload);
  } catch (e) {
    console.error(e);
  }

  return null;
}

export default AuthProvider;
