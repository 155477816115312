import React, { useMemo } from 'react';
import { calculateDuration } from 'utils/activitiesUtils';

interface Props {
  seconds?: number;
  defaultValue?: string;
}

function Duration({ seconds: _seconds, defaultValue }: Props) {
  const duration = useMemo(() => calculateDuration(_seconds), [_seconds]);

  if (!duration) {
    return defaultValue ? <>{defaultValue}</> : null;
  }

  return (
    <>
      {duration.years !== 0 && <span>{duration.years}y </span>}
      {duration.months !== 0 && <span>{duration.months}m </span>}
      {duration.days !== 0 && <span>{duration.days}d </span>}
      {duration.hours !== 0 && <span>{duration.hours}h </span>}
      <span>{duration.minutes}m </span>
      <span>{duration.seconds}s </span>
    </>
  );
}

export default Duration;
