import React, {
  ChangeEvent,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { debounce as _debounce } from 'lodash';

interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  debounce?: number;
  onChange(value: string): void;
}

function Input({ debounce, onChange, ...props }: Props) {
  const [value, setValue] = useState('');

  useDebounceEffect(
    () => {
      onChange(value);
    },
    [value, onChange],
    debounce
  );

  return (
    <input {...props} onChange={(event) => setValue(event.target.value)} />
  );
}

function useDebounceEffect(effect: any, deps: any, delay?: number) {
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => callback(), delay);

    return () => clearTimeout(handler);
  }, [callback, delay]);
}

export default Input;
