import React from 'react';

import { useGetUserProvidersQuery } from '../../../../graphql/types';
import { useParams } from 'react-router-dom';
import Link from '../../../../components/Router/Link';
import DateFormat from '../../../../components/DateFormat/DateFormat';
import Loader from '../../../../components/Loader/Loader';
import Table from '../../../../components/Table/Table';

function UserProviders() {
  const columns = ['Provider', 'Id', 'Disabled', 'Created', 'Updated'];
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetUserProvidersQuery({ variables: { id } });

  if (loading) {
    return <Loader />;
  }

  const providers = data?.providers ?? [];

  return (
    <Table columns={columns}>
      {providers.map((provider) => (
        <tr key={provider.provider}>
          <td>
            <Link to={`/users/${id}/providers/${provider.provider}`}>
              {provider.provider}
            </Link>
          </td>
          <td>
            <div>{provider.providerId}</div>
          </td>
          <td>
            <div>{provider.disabled ? 'YES' : 'NO'}</div>
          </td>
          <td>
            <div>
              <DateFormat
                value={provider.createdAt}
                format="dd/MM/yyyy HH:mm"
              />
            </div>
          </td>
          <td>
            <div>
              <DateFormat
                value={provider.updatedAt}
                format="dd/MM/yyyy HH:mm"
              />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
}

export default UserProviders;
