import React from 'react';
import { useField } from 'formik';

import './TextInput.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  touched?: boolean;
}

function TextInput({ label, error, touched, ...inputProps }: Props) {
  return (
    <label className="Input">
      {label && (
        <div className="Input-label">
          {label}{' '}
          {inputProps.required && <span className="Input-required">*</span>}
          {error && touched && <span className="Input-error">{error}</span>}
        </div>
      )}

      <div className="Input-container">
        <input {...inputProps} />
      </div>
    </label>
  );
}

interface FormikInputProps extends Omit<Props, 'name'> {
  name: string | Array<string | number>;
}

export function TextInputFormik({ label, ...inputProps }: FormikInputProps) {
  const name = Array.isArray(inputProps.name)
    ? inputProps.name.join('.')
    : inputProps.name;

  const [field, { error, touched }] = useField(name);

  return (
    <TextInput
      label={label}
      touched={touched}
      error={error}
      {...inputProps}
      {...field}
    />
  );
}

export default TextInput;
