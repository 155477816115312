import Page from 'components/Page/Page';
import React, { useEffect, useMemo, useState } from 'react';

import './UsersPage.scss';
import { IUserDetailsFragment, useGetUsersLazyQuery } from 'graphql/types';
import _ from 'lodash';
import Link from 'components/Router/Link';
import Table from '../../../components/Table/Table';

function UsersPage() {
  const [getUsers, { data }] = useGetUsersLazyQuery();
  const [users, setUsers] = useState<IUserDetailsFragment[]>([]);

  const searchUsers = useMemo(() => {
    const throttle = _.debounce(async (filter: string) => {
      console.log({ filter });

      return getUsers({
        variables: { filter, take: 10 },
      });
    }, 250);

    return throttle;
  }, []);

  useEffect(() => {
    if (data?.users) {
      setUsers(data.users);
    }
  }, [data?.users]);

  useEffect(() => {
    searchUsers('');
  }, []);

  const columns = ['Name', 'Email', 'Username'];

  return (
    <Page slug="Users">
      <div className="Page-title">Users</div>
      <div>
        <div className="Search">
          <label>
            <svg
              viewBox="0 0 16 16"
              fill="currentColor"
              height="16px"
              width="16px"
            >
              <path d="M6,12A6,6,0,1,1,12,6,6,6,0,0,1,6,12ZM6,1.54A4.46,4.46,0,1,0,10.45,6,4.46,4.46,0,0,0,6,1.54Z" />
              <path d="M15.2,16a.74.74,0,0,1-.53-.22L9.14,10.2A.75.75,0,0,1,10.2,9.14l5.53,5.53a.75.75,0,0,1,0,1.06A.74.74,0,0,1,15.2,16Z" />
            </svg>
          </label>
          <input
            onChange={(e) => searchUsers(e.target.value)}
            placeholder="Search by ID, name, email or username."
          />
        </div>
      </div>
      <Table columns={columns}>
        {users.map((user) => (
          <tr key={user.id}>
            <td>
              <Link to={`/users/${user.id}`}>
                {user.firstName} {user.lastName}
              </Link>
            </td>
            <td>
              <div>{user.email}</div>
            </td>
            <td>
              <div>{user.username}</div>
            </td>
          </tr>
        ))}
      </Table>
    </Page>
  );
}

export default UsersPage;
