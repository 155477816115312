import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactGA from 'react-ga';

import AnalyticsTracker from './components/AnalyticsTracker/AnalyticsTracker';
import AuthProvider from 'providers/AuthProvider';
import configuration from './configuration';

import Root from './Root';

import SearchProvider from './providers/SearchProvider';
import './index.scss';

Sentry.init({
  dsn: configuration.sentry.dsn,
  integrations: [new Integrations.BrowserTracing()],
  environment: configuration.environment,
});

if (configuration.googleAnalyticsTrackingCode) {
  ReactGA.initialize(configuration.googleAnalyticsTrackingCode);
}

ReactDOM.render(
  <BrowserRouter>
    <AnalyticsTracker />
    <AuthProvider
      domain={configuration.apiUrl}
      redirectUri={window.location.origin}
      logoutUrl={`${window.location.origin}/login`}
    >
      <SearchProvider>
        <Root />
      </SearchProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
