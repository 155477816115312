import {
  ICreateEventInput,
  IEventDetailsFragment,
  IEventType,
} from '../../graphql/types';

// Event creation forms
import DistanceEventCreate from './Distance/DistanceEventCreate';
import StepsEventCreate from './Steps/StepsEventCreate';
import TimeboxedEventCreate from './Timeboxed/TimeboxedEventCreate';

// Event edit forms
import DistanceEventEdit from './Distance/DistanceEventEdit';
import TimeboxedEventEdit from './Timeboxed/TimeboxedEventEdit';

// Event details
import EventDetails from './Shared/EventDetails';
import TimeboxedEventDetails from './Timeboxed/TimeboxedEventDetails';

/**
 * Get the customised form for the given event type. This allows us
 * to customise the fields for each type of event and collect data
 * specifically required by each type.
 */
export interface EventCreationProps {
  onSave(values: ICreateEventInput): Promise<void>;
  beforeForm?: any;
}

const EventCreationFormComponents: Record<
  IEventType,
  (arg0: EventCreationProps) => JSX.Element
> = {
  DISTANCE: DistanceEventCreate,
  STEPS: StepsEventCreate,
  TIMEBOXED: TimeboxedEventCreate,
};

export function getEventCreationForm(type: IEventType) {
  return EventCreationFormComponents[type];
}

/**
 * Customised edit forms
 */
export interface EventEditProps {
  event: IEventDetailsFragment;
  onSave(values: ICreateEventInput): Promise<void>;
  beforeForm?: any;
}

const EventEditFormComponents: Record<
  IEventType,
  (arg0: EventEditProps) => JSX.Element
> = {
  DISTANCE: DistanceEventEdit,
  STEPS: DistanceEventEdit,
  TIMEBOXED: TimeboxedEventEdit,
};

export function getEventEditForm(type: IEventType) {
  return EventEditFormComponents[type];
}

/**
 * Customised details sections
 */
const EventDetailsComponents: Record<IEventType, any> = {
  DISTANCE: EventDetails,
  STEPS: EventDetails,
  TIMEBOXED: TimeboxedEventDetails,
};

export function getEventDetails(type: IEventType) {
  console.log(type);
  return EventDetailsComponents[type];
}
