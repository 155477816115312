import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

function ScrollTop({ history }: RouteComponentProps) {
  useEffect(() => {
    return history.listen(() => {
      window.scrollTo(0, 0);
    });
    // eslint-disable-next-line
  }, []);

  return null;
}

export default withRouter(ScrollTop);
