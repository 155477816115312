import React, { useMemo } from 'react';
import Routes from './routes/Routes';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import configuration from './configuration';
import PreferencesProvider from './providers/PreferencesProvider';
import { useAuth } from './providers/AuthProvider';
import HeroProvider from './providers/HeroProvider';
import { onError } from '@apollo/client/link/error';
import ScrollTop from './components/ScrollTop/ScrollTop';
import * as Sentry from '@sentry/react';
import { createUploadLink } from 'apollo-upload-client';

const link = createUploadLink({
  uri: `${configuration.apiUrl}/graphql`,
});

const cache = new InMemoryCache({
  typePolicies: {
    Team: {
      fields: {
        members: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

function Root() {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth();

  const client = useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      const token = isAuthenticated ? await getAccessTokenSilently() : '';

      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map((event) => {
          Sentry.captureEvent(event);
          const { message, locations, path } = event;

          console.log(`[GraphQL error]: `, {
            message,
            locations,
            path,
          });
        });
      }

      const unauthorized = graphQLErrors?.find(
        (e) => e.extensions?.exception.status === 401
      );

      if (unauthorized) {
        logout();
      }

      if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    return new ApolloClient({
      link: ApolloLink.from([authLink, errorLink, link]),
      connectToDevTools: true,
      cache,
    });
    // eslint-disable-next-line
  }, [isAuthenticated, getAccessTokenSilently]);

  return (
    <HeroProvider>
      <PreferencesProvider>
        <ApolloProvider client={client}>
          <ScrollTop />
          <Routes />
        </ApolloProvider>
      </PreferencesProvider>
    </HeroProvider>
  );
}

export default Root;
