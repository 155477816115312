import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useProfile } from 'hooks';
import { useAuth } from 'providers/AuthProvider';
import DashboardPage from './dashboard/DashboardPage';
import TeamsRoute from './teams/TeamsRoute';
import { createRedirect } from '../components/Router/Redirect';
// import SearchBar from '../components/SearchBar/SearchBar';
// import { useSearchBox } from '../providers/SearchProvider';
import Sidebar from '../components/Sidebar/Sidebar';
import UsersRoute from './users/UsersRoute';

import './Routes.scss';
import EventsRoute from './events/EventsRoute';

function AppRoute() {
  const { profile } = useProfile();
  // const { active } = useSearchBox();

  if (!profile) {
    return null;
  }

  return (
    <>
      <Sidebar />
      <main className="Main">
        <div className="Main-content">
          <Switch>
            <Route path="/" exact component={DashboardPage} />
            <Route path="/users" component={UsersRoute} />
            <Route path="/teams" component={TeamsRoute} />
            <Route path="/events" component={EventsRoute} />
          </Switch>
        </div>
      </main>
    </>
  );
}

interface Props {
  path: string;
  component: React.ComponentType;
}

function AuthRoute({ path, component }: Props) {
  const { isAuthenticated, loading, login } = useAuth();

  if (loading) {
    return null;
  }

  if (!isAuthenticated) {
    const redirect = createRedirect();

    login(redirect);

    return null;
  }

  return <Route path={path} component={component} />;
}

function Routes() {
  return (
    <>
      <Switch>
        {/*<Route path="/login" exact component={LoginPage} />*/}
        <AuthRoute path="/" component={AppRoute} />
      </Switch>
    </>
  );
}

export default Routes;
