import ReactGA from 'react-ga';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import configuration from '../../configuration';

function AnalyticsTracker({ history }: RouteComponentProps) {
  useEffect(() => {
    return history.listen((location) => {
      if (!configuration.googleAnalyticsTrackingCode) {
        return;
      }

      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
    // eslint-disable-next-line
  }, []);

  return null;
}

export default withRouter(AnalyticsTracker);
