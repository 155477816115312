import React from 'react';

import { useGetUserAddressesQuery } from '../../../../graphql/types';
import { useParams } from 'react-router-dom';
import Link from '../../../../components/Router/Link';
import Loader from '../../../../components/Loader/Loader';
import Table from '../../../../components/Table/Table';

function UserAddresses() {
  const columns = ['id', 'Type', 'Description', 'Post Code'];
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetUserAddressesQuery({ variables: { id } });

  if (loading) {
    return <Loader />;
  }

  const addresses = data?.addresses ?? [];

  return (
    <Table columns={columns}>
      {addresses.map((address) => (
        <tr key={address.id}>
          <td>
            <Link to={`/users/${id}/addresses/${address.id}`}>
              {address.id}
            </Link>
          </td>
          <td>
            <div>{address.type}</div>
          </td>
          <td>
            <div>{address.address1}</div>
          </td>
          <td>
            <div>{address.postcode}</div>
          </td>
        </tr>
      ))}
    </Table>
  );
}

export default UserAddresses;
