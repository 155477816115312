import React, { useState } from 'react';

const HERO_IMG = `/images/default-header.jpg`;

interface IHeroContext {
  image?: string;
  setImage(image?: string): void;
}

export const HeroContext = React.createContext<IHeroContext>({
  image: HERO_IMG,
  setImage(): void {},
});

export const useHero = () => React.useContext(HeroContext);

function HeroProvider({ children }: React.PropsWithChildren<unknown>) {
  const [image, setImage] = useState<string | undefined>();

  return (
    <HeroContext.Provider value={{ image: image ?? HERO_IMG, setImage }}>
      {children}
    </HeroContext.Provider>
  );
}

export default HeroProvider;
