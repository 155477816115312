import Page from 'components/Page/Page';
import React from 'react';

import './EventPage.scss';

import { NavLink, Route, useParams } from 'react-router-dom';
import { useGetEventQuery } from '../../../graphql/types';
import Loader from 'components/Loader/Loader';
import EventDetails from './EventDetails/EventDetails';
import EventStats from './EventStats/EventStats';
import EventMembers from './EventMembers/EventMembers';
import EventProvider from '../../../providers/EventProvider';
import EventResults from './EventResults/EventResults';
import Button from 'components/Buttons/Button/Button';
import Link from 'components/Router/Link';

function EventPage() {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetEventQuery({ variables: { id } });

  const event = data?.event;

  if (loading) {
    return <Loader />;
  }

  if (!event) {
    return <div>No event found</div>;
  }

  const { name } = event;

  return (
    <EventProvider event={event}>
      <Page className="EventPage">
        <div className="Page-header">
          <div>
            <h3>{name}</h3>
            <span>{id}</span>
          </div>

          <div className="Page-header-actions">
            <Button>
              <Link to={`/events/${id}/edit`}>Edit</Link>
            </Button>
          </div>

          <nav className="Page-header-nav">
            <NavLink to={`/events/${id}`} exact>
              Details
            </NavLink>
            <NavLink to={`/events/${id}/statistics`}>Statistics</NavLink>
            <NavLink to={`/events/${id}/results`}>Results</NavLink>
          </nav>
        </div>
        <div className="Page-contents">
          <Route path={`/events/:id`} exact component={EventDetails} />
          <Route path={`/events/:id/statistics`} component={EventStats} />
          <Route path={`/events/:id/members`} component={EventMembers} />
          <Route path={`/events/:id/results`} component={EventResults} />
        </div>
      </Page>
    </EventProvider>
  );
}

export default EventPage;
