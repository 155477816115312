import React, { useMemo } from 'react';
import Page from 'components/Page/Page';
import _ from 'lodash';

import './DashboardPage.scss';
import Panel from '../../components/Panel/Panel';
import ReactEcharts from 'echarts-for-react';
import { useGetEntriesByDateQuery } from '../../graphql/types';
import {
  eachDayOfInterval,
  endOfToday,
  format,
  isSameDay,
  min,
  parseISO,
} from 'date-fns';

function DashboardPage() {
  const { data } = useGetEntriesByDateQuery({ pollInterval: 20000 });
  const entriesByDate = data?.entries;

  const { entries, teams } = useMemo(() => {
    if (!entriesByDate) {
      return { x: [] };
    }

    const entries = entriesByDate.map((e) => ({
      date: parseISO(e.date),
      entries: e.entries,
      teams: e.teams,
    }));

    const start = min(entries.map((e) => e.date));
    const end = endOfToday();

    const range = eachDayOfInterval({
      start,
      end,
    });

    let totalEntries = 0;
    let totalTeams = 0;
    const es = [];

    for (const date of range) {
      const day = entries.find((e) => isSameDay(e.date, date));

      if (!day) {
        es.push({
          date,
          entries: {
            total: totalEntries,
            count: 0,
          },
          teams: {
            total: totalTeams,
            count: 0,
          },
        });

        continue;
      }

      totalTeams += day.teams;
      totalEntries += day.entries;

      es.push({
        date,
        entries: {
          total: totalEntries,
          count: day.entries,
        },
        teams: {
          total: totalTeams,
          count: day.teams,
        },
      });
    }

    const entriesX = es.map((e) => {
      return {
        name: e.date.toISOString(),
        entries: e.entries,
        teams: e.teams,
        value: [format(e.date, 'yyyy/MM/dd'), e.entries.total],
      };
    });

    const teamsX = es.map((e) => {
      return {
        name: e.date.toISOString(),
        entries: e.entries,
        teams: e.teams,
        value: [format(e.date, 'yyyy/MM/dd'), e.teams.total],
      };
    });

    return {
      entries: entriesX,
      teams: teamsX,
    };
  }, [entriesByDate]);

  return (
    <Page className="DashboardPage" slug="Dashboard">
      <div className="Page-title">Dashboards</div>
      <Panel>
        <div className="Panel-header">
          Entries - {entriesByDate && _.sumBy(entriesByDate, 'entries')}
        </div>
        <ReactEcharts
          opts={{
            renderer: 'svg',
            height: 300,
            width: 'auto',
          }}
          option={{
            xAxis: {
              type: 'time',
              splitLine: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  color: '#C6BECF',
                },
              },
              minorTick: {
                show: true,
              },
              axisTick: {
                lineStyle: {
                  color: '#C6BECF',
                },
              },
              axisLabel: {
                color: '#C6BECF',
                formatter(value: any) {
                  return `${format(value, 'MMM do')}`;
                },
              },
            },
            tooltip: {
              trigger: 'axis',
              formatter([{ data }]: any) {
                const date = parseISO(data.name);

                const entriesDescription = `Entries: ${data.entries.total} (+${data.entries.count})`;
                const teamsDescription = `Teams: ${data.teams.total} (+${data.teams.count})`;

                return `${format(
                  date,
                  'do MMMM yyyy'
                )} - ${entriesDescription} ${teamsDescription}`;
              },
              axisPointer: {
                animation: false,
              },
            },
            yAxis: {
              type: 'value',
              boundaryGap: [0, '100%'],
              axisLabel: {
                color: '#C6BECF',
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: '#FAF9FB',
                },
              },
            },
            grid: {
              left: 55,
              top: 25,
              right: 25,
              bottom: 50,
            },
            series: [
              {
                type: 'line',
                showSymbol: false,
                hoverAnimation: false,
                data: entries,
                lineStyle: {
                  color: '#444674',
                },
              },
              {
                type: 'line',
                showSymbol: false,
                hoverAnimation: false,
                data: teams,
                lineStyle: {
                  color: '#C6BECF',
                  type: 'dotted',
                },
              },
            ],
          }}
        />
      </Panel>
    </Page>
  );
}

export default DashboardPage;
