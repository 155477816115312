import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  MultilingualString: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type IActivity = {
  id: Scalars['ID'];
  description: Scalars['String'];
  distance: Scalars['Float'];
  steps?: Maybe<Scalars['Float']>;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  type: IActivityType;
  source: IActivitySource;
  sourceId?: Maybe<Scalars['String']>;
  manual: Scalars['Boolean'];
  photos?: Maybe<Array<Scalars['String']>>;
  map?: Maybe<IActivityMap>;
  user: IUserOverview;
};

export type IActivityMap = {
  start?: Maybe<ILngLat>;
  end?: Maybe<ILngLat>;
  line?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
};

export type IActivityOverview = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
  distance: Scalars['Float'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  type: IActivityType;
  manual: Scalars['Boolean'];
};

export type IActivitySource =
  | 'RUN_THINGS'
  | 'STRAVA';

export type IActivityStatistics = {
  name: Scalars['String'];
  total?: Maybe<Scalars['Float']>;
  totalDistance?: Maybe<Scalars['Float']>;
  averageDistance?: Maybe<Scalars['Float']>;
  totalSeconds?: Maybe<Scalars['Float']>;
  averageSeconds?: Maybe<Scalars['Float']>;
  longest?: Maybe<Scalars['Float']>;
};

export type IActivityType =
  | 'RIDE'
  | 'RUN'
  | 'SWIM'
  | 'HIKE'
  | 'WALK'
  | 'GOLF'
  | 'ALPINE_SKI'
  | 'BACK_COUNTRY_SKI'
  | 'CANOEING'
  | 'CROSSFIT'
  | 'EBIKE_RIDE'
  | 'ELLIPTICAL'
  | 'HAND_CYCLE'
  | 'ICE_SKATE'
  | 'INLINE_SKATE'
  | 'KAYAKING'
  | 'KITESURF'
  | 'NORDIC_SKI'
  | 'ROCK_CLIMBING'
  | 'ROLLER_SKI'
  | 'ROWING'
  | 'SNOWBOARD'
  | 'SNOWSHOE'
  | 'SOCCER'
  | 'STAIR_STEPPER'
  | 'STANDUP_PADDLING'
  | 'SURFING'
  | 'VELOMOBILE'
  | 'VIRTUAL_RIDE'
  | 'VIRTUAL_RUN'
  | 'WEIGHT_TRAINING'
  | 'WHEELCHAIR'
  | 'WINDSURF'
  | 'WORKOUT'
  | 'YOGA'
  | 'STEPS';

export type IAddress = {
  id: Scalars['ID'];
  type: IAddressType;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2: Scalars['String'];
  city: Scalars['String'];
  county: Scalars['String'];
  country: Scalars['String'];
  postcode: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export type IAddressType =
  | 'SHIPPING'
  | 'BILLING';

export type IAdminEventMember = {
  id: Scalars['ID'];
  user: IUser;
  target: Scalars['Float'];
  distance: Scalars['Float'];
  group?: Maybe<IEventGroup>;
  team?: Maybe<IEventEntryTeam>;
};

export type ICreateAccountInput = {
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  profilePicUrl?: Maybe<Scalars['String']>;
  password: Scalars['String'];
};

export type ICreateActivityInput = {
  description: Scalars['String'];
  distance: Scalars['Float'];
  steps?: Maybe<Scalars['Float']>;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  type: IActivityType;
  timezone: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
};

export type ICreateEventInput = {
  name: Scalars['String'];
  description: Scalars['MultilingualString'];
  type: IEventType;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  groups: Array<IEventGroupInput>;
  config: IEventConfigInput;
};


export type IEntriesByDate = {
  date: Scalars['DateTime'];
  entries: Scalars['Float'];
  teams: Scalars['Float'];
};

export type IEvent = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['MultilingualString'];
  type: IEventType;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  entry?: Maybe<IEventEntry>;
  settings: IEventSettings;
  groups: Array<IEventGroup>;
  distances?: Maybe<Array<Scalars['Float']>>;
  config: IEventConfig;
  member: IEventMember;
};

export type IEventActivity = {
  id: Scalars['ID'];
  distance: Scalars['Float'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  type: IActivityType;
};

export type IEventConfig = {
  changesDisabled?: Maybe<Scalars['Boolean']>;
  leaderboardDisabled?: Maybe<Scalars['Boolean']>;
  targetDisabled?: Maybe<Scalars['Boolean']>;
  marketingId?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  distances?: Maybe<Array<Scalars['Float']>>;
  activities?: Maybe<Array<IActivity>>;
  description?: Maybe<Scalars['MultilingualString']>;
  lifeplus?: Maybe<ILifeplus>;
  timebox?: Maybe<ITimebox>;
};

export type IEventConfigInput = {
  marketingId: Scalars['String'];
  header: Scalars['String'];
  thumbnail: Scalars['String'];
  activities: Array<IActivityType>;
  timebox?: Maybe<ITimeboxConfigInput>;
  mandatoryEvidence?: Maybe<Scalars['Boolean']>;
  distances?: Maybe<Array<Scalars['Float']>>;
};

export type IEventEntry = {
  team?: Maybe<IEventEntryTeam>;
  target: Scalars['Float'];
  private: Scalars['Boolean'];
  group?: Maybe<IEventGroup>;
  metadata: IEventEntryMetadata;
};

export type IEventEntryMetadata = {
  lifeplus?: Maybe<ILifeplusMetadata>;
};

export type IEventEntryTeam = {
  id: Scalars['ID'];
  name: Scalars['String'];
  membership?: Maybe<ITeamMembership>;
  membersCount: Scalars['Float'];
  target: Scalars['Float'];
  group?: Maybe<IEventGroup>;
  config?: Maybe<ITeamConfig>;
};

export type IEventGroup = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  target: Scalars['Float'];
};

export type IEventGroupInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  target: Scalars['Float'];
};

export type IEventLeaderboard = {
  position?: Maybe<IPosition>;
  positions: Array<IPosition>;
  total: Scalars['Float'];
};

export type IEventMember = {
  target: Scalars['Float'];
  timezone: Scalars['String'];
  private: Scalars['Boolean'];
  createdBy?: Maybe<Scalars['String']>;
  user: IUser;
  userId?: Maybe<Scalars['String']>;
  group?: Maybe<IEventGroup>;
  groupId?: Maybe<Scalars['String']>;
  metadata?: Maybe<IEventMemberMetadata>;
};

export type IEventMemberLifeplus = {
  hasPIN: Scalars['Boolean'];
};

export type IEventMemberMetadata = {
  lifeplus?: Maybe<IEventMemberLifeplus>;
  timeboxed?: Maybe<IEventMemberTimebox>;
};

export type IEventMemberTimebox = {
  start: Scalars['String'];
};

export type IEventSettings = {
  changesDisabled: Scalars['Boolean'];
  leaderboardDisabled: Scalars['Boolean'];
  targetDisabled: Scalars['Boolean'];
  marketingId: Scalars['String'];
  thumbnail: Scalars['String'];
  header: Scalars['String'];
  distances?: Maybe<Array<Scalars['Float']>>;
  activities: Array<IActivityType>;
  hasGroups: Scalars['Boolean'];
  lifeplus?: Maybe<ILifeplusConfig>;
  timebox?: Maybe<ITimeboxedConfig>;
  mandatoryEvidence?: Maybe<Scalars['Boolean']>;
};

export type IEventStatistics = {
  activities: Array<IActivityStatistics>;
};

export type IEventType =
  | 'DISTANCE'
  | 'STEPS'
  | 'TIMEBOXED';

export type IImageSize = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};


export type IJoinTeam = {
  id: Scalars['ID'];
  name: Scalars['String'];
  status: IJoinTeamStatus;
  event: IEvent;
};

export type IJoinTeamStatus =
  | 'JOINABLE'
  | 'CLAIMABLE'
  | 'ALREADY_JOINED'
  | 'DISABLED'
  | 'PENDING'
  | 'FULL';

export type ILeaderboard = {
  users: IEventLeaderboard;
  teams: IEventLeaderboard;
  groups: IEventLeaderboard;
};


export type ILeaderboardUsersArgs = {
  filter?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
};


export type ILeaderboardTeamsArgs = {
  filter?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
};


export type ILeaderboardGroupsArgs = {
  filter?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
};

export type ILifeplus = {
  showPIN?: Maybe<Scalars['Boolean']>;
};

export type ILifeplusConfig = {
  showPIN?: Maybe<Scalars['Boolean']>;
};

export type ILifeplusMetadata = {
  hasPIN?: Maybe<Scalars['Boolean']>;
};

export type ILifeplusUpdateInput = {
  hasPIN: Scalars['Boolean'];
};

export type ILngLat = {
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
};

export type ILocale =
  | 'EN'
  | 'ES'
  | 'FR'
  | 'IT'
  | 'DE'
  | 'NL';

export type ILoginInput = {
  emailOrUsername: Scalars['String'];
  password: Scalars['String'];
};

export type ILoginResult = {
  status: ILoginStatus;
  token?: Maybe<IToken>;
};

export type ILoginStatus =
  | 'SUCCESS'
  | 'INVALID_CREDENTIALS'
  | 'RESET_PASSWORD';

export type IMembershipStatus =
  | 'ACTIVE'
  | 'PENDING';

export type IMessage = {
  id: Scalars['ID'];
  subject: Scalars['String'];
  sent: Scalars['DateTime'];
  type: IMessageType;
  text: Scalars['String'];
};

export type IMessageType =
  | 'EMAIL'
  | 'SMS';


export type IMutation = {
  updateProfile: IUserProfile;
  resetPassword: Scalars['Boolean'];
  updatePassword: Scalars['Boolean'];
  unlinkProvider: IUserProfile;
  updateLocale: Scalars['Boolean'];
  deleteAccount: Scalars['Boolean'];
  updateTeam: ITeam;
  joinTeam: Scalars['Boolean'];
  acceptMember: ITeamMember;
  rejectMember: Scalars['Boolean'];
  leaveTeam: Scalars['Boolean'];
  updateEventEntry: IEvent;
  updateEventPrivacy: IEvent;
  updateLifeplus: IEvent;
  stravaLink: IStravaLinkResult;
  createActivity: IActivity;
  deleteActivity: IActivity;
  login: ILoginResult;
  createAccount: IToken;
  refreshToken: IToken;
  logout: Scalars['Boolean'];
  updateAddress: IAddress;
  _deleteUserProvider: Scalars['Boolean'];
  _deleteTeamMember: Scalars['Boolean'];
  _login: IToken;
  _createEvent: IEvent;
  _updateEvent: IEvent;
  generateResults: Scalars['JSON'];
  uploadImage: Scalars['String'];
};


export type IMutationUpdateProfileArgs = {
  profile: IUpdateProfileInput;
};


export type IMutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type IMutationUpdatePasswordArgs = {
  input: IUpdatePasswordInput;
};


export type IMutationUnlinkProviderArgs = {
  id: Scalars['ID'];
};


export type IMutationUpdateLocaleArgs = {
  locale: ILocale;
};


export type IMutationUpdateTeamArgs = {
  input: IUpdateTeamInput;
  id: Scalars['ID'];
};


export type IMutationJoinTeamArgs = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['ID']>;
};


export type IMutationAcceptMemberArgs = {
  memberId: Scalars['ID'];
  id: Scalars['ID'];
};


export type IMutationRejectMemberArgs = {
  memberId: Scalars['ID'];
  id: Scalars['ID'];
};


export type IMutationLeaveTeamArgs = {
  newDistance?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
};


export type IMutationUpdateEventEntryArgs = {
  input: IUpdateEventEntryInput;
  id: Scalars['ID'];
};


export type IMutationUpdateEventPrivacyArgs = {
  private: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type IMutationUpdateLifeplusArgs = {
  lifeplus: ILifeplusUpdateInput;
  id: Scalars['ID'];
};


export type IMutationStravaLinkArgs = {
  token: Scalars['String'];
  force?: Maybe<Scalars['Boolean']>;
};


export type IMutationCreateActivityArgs = {
  input: ICreateActivityInput;
};


export type IMutationDeleteActivityArgs = {
  id: Scalars['ID'];
};


export type IMutationLoginArgs = {
  input: ILoginInput;
};


export type IMutationCreateAccountArgs = {
  input: ICreateAccountInput;
};


export type IMutationUpdateAddressArgs = {
  address: IUpdateAddressInput;
  id: Scalars['ID'];
};


export type IMutation_DeleteUserProviderArgs = {
  id: Scalars['ID'];
};


export type IMutation_DeleteTeamMemberArgs = {
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type IMutation_LoginArgs = {
  id: Scalars['ID'];
};


export type IMutation_CreateEventArgs = {
  input: ICreateEventInput;
};


export type IMutation_UpdateEventArgs = {
  input: IUpdateEventInput;
};


export type IMutationGenerateResultsArgs = {
  id: Scalars['ID'];
};


export type IMutationUploadImageArgs = {
  size?: Maybe<IImageSize>;
  file: Scalars['Upload'];
};

export type IPosition = {
  id: Scalars['String'];
  position: Scalars['Float'];
  name: Scalars['String'];
  distance?: Maybe<Scalars['Float']>;
  steps?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  group?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  image?: Maybe<Scalars['String']>;
};

export type IProvider =
  | 'FACEBOOK'
  | 'GOOGLE'
  | 'TWITTER'
  | 'STRAVA'
  | 'INSTAGRAM'
  | 'RUN_THINGS';

export type IQuery = {
  profile: IUserProfile;
  teams: Array<ITeamOverview>;
  team: ITeam;
  findTeams: Array<ITeamOverview>;
  teamByCode: IJoinTeam;
  teamRequest: IJoinTeam;
  teamActivities: Array<IActivityOverview>;
  events: Array<IEvent>;
  event: IEvent;
  activities: Array<IActivity>;
  activity: IActivity;
  activityMap?: Maybe<IActivityMap>;
  addresses: Array<IAddress>;
  address: IAddress;
  search: Array<ISearchResult>;
  _entriesByDate: Array<IEntriesByDate>;
  _users: Array<IUser>;
  _user: IUser;
  _userProviders: Array<IUserProvider>;
  _userTeams: Array<ITeam>;
  _userAddresses: Array<IAddress>;
  _userEvents: Array<IEvent>;
  _userMessages: Array<IMessage>;
  _userActivities: Array<IActivity>;
  _teams: Array<ITeamOverview>;
  _team: ITeam;
  _teamMembers: Array<ITeamMember>;
  _events: Array<IEvent>;
  _event: IEvent;
  _eventActivities: Array<IEventActivity>;
  _eventStatistics: IEventStatistics;
  _eventMembers: Array<IAdminEventMember>;
  _eventResults: Scalars['JSON'];
  leaderboard: ILeaderboard;
  getEventResults: Scalars['JSON'];
  feed: Array<IActivity>;
};


export type IQueryTeamArgs = {
  id: Scalars['ID'];
};


export type IQueryFindTeamsArgs = {
  query: Scalars['String'];
};


export type IQueryTeamByCodeArgs = {
  code: Scalars['ID'];
};


export type IQueryTeamRequestArgs = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['ID']>;
};


export type IQueryTeamActivitiesArgs = {
  id: Scalars['ID'];
};


export type IQueryEventArgs = {
  id: Scalars['ID'];
};


export type IQueryActivitiesArgs = {
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  types?: Maybe<Array<IActivityType>>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  event?: Maybe<Scalars['ID']>;
};


export type IQueryActivityArgs = {
  id: Scalars['ID'];
};


export type IQueryActivityMapArgs = {
  id: Scalars['ID'];
};


export type IQueryAddressArgs = {
  id: Scalars['ID'];
};


export type IQuerySearchArgs = {
  input: ISearchInput;
};


export type IQuery_UsersArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
};


export type IQuery_UserArgs = {
  id: Scalars['ID'];
};


export type IQuery_UserProvidersArgs = {
  id: Scalars['ID'];
};


export type IQuery_UserTeamsArgs = {
  id: Scalars['ID'];
};


export type IQuery_UserAddressesArgs = {
  id: Scalars['ID'];
};


export type IQuery_UserEventsArgs = {
  id: Scalars['ID'];
};


export type IQuery_UserMessagesArgs = {
  id: Scalars['ID'];
};


export type IQuery_UserActivitiesArgs = {
  id: Scalars['ID'];
};


export type IQuery_TeamsArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
};


export type IQuery_TeamArgs = {
  id: Scalars['ID'];
};


export type IQuery_TeamMembersArgs = {
  id: Scalars['ID'];
};


export type IQuery_EventsArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
};


export type IQuery_EventArgs = {
  id: Scalars['ID'];
};


export type IQuery_EventActivitiesArgs = {
  id: Scalars['ID'];
};


export type IQuery_EventStatisticsArgs = {
  id: Scalars['ID'];
};


export type IQuery_EventMembersArgs = {
  id: Scalars['ID'];
};


export type IQuery_EventResultsArgs = {
  id: Scalars['ID'];
};


export type IQueryLeaderboardArgs = {
  id: Scalars['ID'];
};


export type IQueryGetEventResultsArgs = {
  id: Scalars['ID'];
};


export type IQueryFeedArgs = {
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
};

export type IScope =
  | 'ADMIN';

export type ISearchInput = {
  query: Scalars['String'];
  eventId?: Maybe<Scalars['ID']>;
  types?: Maybe<Array<ISearchResultType>>;
};

export type ISearchResult = {
  id: Scalars['ID'];
  description: Scalars['String'];
  type: ISearchResultType;
  logoUrl?: Maybe<Scalars['String']>;
};

export type ISearchResultType =
  | 'TEAM'
  | 'MEMBER'
  | 'EVENT';

export type IStravaLinkResult = {
  status: IStravaLinkResultStatus;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type IStravaLinkResultStatus =
  | 'SUCCESS'
  | 'CONFLICTED'
  | 'ERROR';

export type ITeam = {
  id: Scalars['ID'];
  name: Scalars['String'];
  isPrivate: Scalars['Boolean'];
  members: Array<ITeamMember>;
  target: Scalars['Float'];
  event: IEvent;
  membership?: Maybe<ITeamMembership>;
  settings?: Maybe<ITeamSettings>;
  group?: Maybe<IEventGroup>;
  displayLeaderboard: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  config?: Maybe<ITeamConfig>;
};

export type ITeamConfig = {
  legacyId?: Maybe<Scalars['String']>;
  timeboxed?: Maybe<ITeamConfigTimebox>;
};

export type ITeamConfigTimebox = {
  start?: Maybe<Scalars['String']>;
};

export type ITeamMember = {
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  username: Scalars['String'];
  profilePicUrl?: Maybe<Scalars['String']>;
  status: IMembershipStatus;
  purchased: Scalars['Boolean'];
};

export type ITeamMembership = {
  isAdmin: Scalars['Boolean'];
  status: IMembershipStatus;
};

export type ITeamOverview = {
  id: Scalars['ID'];
  name: Scalars['String'];
  claimable: Scalars['Boolean'];
  memberCount: Scalars['Float'];
  isPrivate: Scalars['Boolean'];
  event: IEvent;
  target: Scalars['Float'];
  membership?: Maybe<ITeamMembership>;
  logoUrl?: Maybe<Scalars['String']>;
  config?: Maybe<ITeamConfig>;
};

export type ITeamSettings = {
  approvalRequired: Scalars['Boolean'];
  inviteUrl: Scalars['String'];
  remainingEntries: Scalars['Float'];
  entries: Scalars['Float'];
  code: Scalars['ID'];
  changesDisabled: Scalars['Boolean'];
};

export type ITimebox = {
  distance: Scalars['Float'];
  duration: Scalars['Float'];
};

export type ITimeboxConfigInput = {
  distance: Scalars['Float'];
  duration: Scalars['Float'];
};

export type ITimeboxedConfig = {
  distance: Scalars['Float'];
  duration: Scalars['Float'];
};

export type IToken = {
  accessToken: Scalars['String'];
  expiresIn: Scalars['Float'];
  idToken?: Maybe<Scalars['String']>;
};

export type IUpdateAddressInput = {
  id: Scalars['ID'];
  type: IAddressType;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2: Scalars['String'];
  city: Scalars['String'];
  county: Scalars['String'];
  country: Scalars['String'];
  postcode: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export type IUpdateEventEntryInput = {
  target: Scalars['Float'];
  timeboxedStart?: Maybe<Scalars['DateTime']>;
};

export type IUpdateEventGroupInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  target: Scalars['Float'];
};

export type IUpdateEventInput = {
  name: Scalars['String'];
  description: Scalars['MultilingualString'];
  type: IEventType;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  groups: Array<IUpdateEventGroupInput>;
  config: IEventConfigInput;
  id: Scalars['String'];
};

export type IUpdatePasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type IUpdateProfileInput = {
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  profilePicUrl?: Maybe<Scalars['String']>;
};

export type IUpdateTeamInput = {
  target: Scalars['Float'];
  approvalRequired: Scalars['Boolean'];
  name: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  timeboxedStart?: Maybe<Scalars['DateTime']>;
};


export type IUser = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  profilePicUrl?: Maybe<Scalars['String']>;
};

export type IUserOverview = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};

export type IUserProfile = {
  id: Scalars['ID'];
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  profilePicUrl?: Maybe<Scalars['String']>;
  providers: Array<IUserProvider>;
  isComplete: Scalars['Boolean'];
  scopes: Array<IScope>;
};

export type IUserProvider = {
  id: Scalars['ID'];
  providerId: Scalars['ID'];
  provider: IProvider;
  expired: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  disabled: Scalars['Boolean'];
};

export type ICreateActivityMutationVariables = Exact<{
  input: ICreateActivityInput;
}>;


export type ICreateActivityMutation = { activity: IActivityDetailsFragmentFragment };

export type IAddressDetailsFragment = Pick<IAddress, 'id' | 'address1' | 'address2' | 'city' | 'company' | 'country' | 'county' | 'email' | 'firstName' | 'lastName' | 'postcode' | 'type'>;

export type IUpdateAddressMutationVariables = Exact<{
  id: Scalars['ID'];
  address: IUpdateAddressInput;
}>;


export type IUpdateAddressMutation = { updateAddress: IAddressDetailsFragment };

export type IGetAddressQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetAddressQuery = { address: IAddressDetailsFragment };

export type ICreateEventMutationVariables = Exact<{
  input: ICreateEventInput;
}>;


export type ICreateEventMutation = { event: IEventDetailsFragment };

export type IUpdateEventMutationVariables = Exact<{
  input: IUpdateEventInput;
}>;


export type IUpdateEventMutation = { event: IEventDetailsFragment };

export type IGenerateResultsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGenerateResultsMutation = { result: IMutation['generateResults'] };

export type IUploadImageMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type IUploadImageMutation = { url: IMutation['uploadImage'] };

export type IUpdateTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  input: IUpdateTeamInput;
}>;


export type IUpdateTeamMutation = { team: ITeamDetailsFragment };

export type IJoinTeamMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['ID']>;
}>;


export type IJoinTeamMutation = { result: IMutation['joinTeam'] };

export type IAcceptMemberMutationVariables = Exact<{
  id: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type IAcceptMemberMutation = { member: Pick<ITeamMember, 'id' | 'isAdmin' | 'firstName' | 'lastName' | 'status'> };

export type IRejectMemberMutationVariables = Exact<{
  id: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type IRejectMemberMutation = { result: IMutation['rejectMember'] };

export type IDeleteTeamMemberMutationVariables = Exact<{
  userId: Scalars['ID'];
  teamId: Scalars['ID'];
}>;


export type IDeleteTeamMemberMutation = { result: IMutation['_deleteTeamMember'] };

export type ICreateAccountMutationVariables = Exact<{
  input: ICreateAccountInput;
}>;


export type ICreateAccountMutation = { token: Pick<IToken, 'accessToken' | 'expiresIn' | 'idToken'> };

export type ILoginMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ILoginMutation = { token: Pick<IToken, 'accessToken' | 'expiresIn' | 'idToken'> };

export type IUpdateProfileMutationVariables = Exact<{
  profile: IUpdateProfileInput;
}>;


export type IUpdateProfileMutation = { updateProfile: IUserProfileFragment };

export type IResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type IResetPasswordMutation = { result: IMutation['resetPassword'] };

export type IUpdatePasswordMutationVariables = Exact<{
  input: IUpdatePasswordInput;
}>;


export type IUpdatePasswordMutation = { result: IMutation['updatePassword'] };

export type IRefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type IRefreshTokenMutation = { token: Pick<IToken, 'accessToken' | 'expiresIn' | 'idToken'> };

export type IActivityDetailsFragmentFragment = Pick<IActivity, 'id' | 'description' | 'distance' | 'start' | 'end' | 'type' | 'source' | 'manual' | 'createdAt'>;

export type IGetActivitiesQueryVariables = Exact<{
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
}>;


export type IGetActivitiesQuery = { activities: Array<IActivityDetailsFragmentFragment> };

export type IGetActivityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetActivityQuery = { activity: IActivityDetailsFragmentFragment };

export type IGetUserActivitiesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetUserActivitiesQuery = { activities: Array<IActivityDetailsFragmentFragment> };

export type IGetAddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetAddressesQuery = { addresses: Array<IAddressDetailsFragment> };

export type IGetUserAddressesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetUserAddressesQuery = { addresses: Array<IAddressDetailsFragment> };

export type IEventDetailsFragment = (
  Pick<IEvent, 'id' | 'name' | 'description' | 'type' | 'start' | 'end'>
  & { groups: Array<Pick<IEventGroup, 'id' | 'name' | 'description' | 'target'>>, settings: (
    Pick<IEventSettings, 'marketingId' | 'thumbnail' | 'header' | 'activities' | 'distances' | 'mandatoryEvidence'>
    & { timebox?: Maybe<Pick<ITimeboxedConfig, 'distance' | 'duration'>> }
  ), entry?: Maybe<(
    Pick<IEventEntry, 'target'>
    & { team?: Maybe<(
      Pick<IEventEntryTeam, 'id' | 'name' | 'target' | 'membersCount'>
      & { membership?: Maybe<Pick<ITeamMembership, 'status' | 'isAdmin'>> }
    )> }
  )> }
);

export type IEventActivityFragment = Pick<IEventActivity, 'id' | 'start' | 'end' | 'distance' | 'type'>;

export type IActivityStatisticsFragment = Pick<IActivityStatistics, 'total' | 'totalDistance' | 'totalSeconds' | 'averageDistance' | 'averageSeconds' | 'longest'>;

export type IEventStatisticsFragment = { activities: Array<Pick<IActivityStatistics, 'name' | 'total' | 'totalDistance' | 'averageDistance' | 'totalSeconds' | 'averageSeconds' | 'longest'>> };

export type IGetEventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetEventQuery = { event: IEventDetailsFragment };

export type IGetEventsQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type IGetEventsQuery = { events: Array<IEventDetailsFragment> };

export type IGetEventStatisticsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetEventStatisticsQuery = { statistics: IEventStatisticsFragment };

export type IGetUserEventsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetUserEventsQuery = { events: Array<IEventDetailsFragment> };

export type IGetEntriesByDateQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetEntriesByDateQuery = { entries: Array<Pick<IEntriesByDate, 'date' | 'entries' | 'teams'>> };

export type IGetEventMembersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetEventMembersQuery = { members: Array<(
    Pick<IAdminEventMember, 'id' | 'distance' | 'target'>
    & { user: Pick<IUser, 'id' | 'firstName' | 'lastName' | 'email' | 'username'>, team?: Maybe<(
      Pick<IEventEntryTeam, 'id' | 'name' | 'target'>
      & { membership?: Maybe<Pick<ITeamMembership, 'isAdmin' | 'status'>>, group?: Maybe<Pick<IEventGroup, 'id' | 'name' | 'target'>> }
    )>, group?: Maybe<Pick<IEventGroup, 'id' | 'name' | 'target'>> }
  )> };

export type IGetEventResultsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetEventResultsQuery = { results: IQuery['getEventResults'] };

export type IMessageDetailsFragment = Pick<IMessage, 'id' | 'subject' | 'type' | 'sent' | 'text'>;

export type IGetUserMessagesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetUserMessagesQuery = { messages: Array<IMessageDetailsFragment> };

export type ISearchQueryVariables = Exact<{
  input: ISearchInput;
}>;


export type ISearchQuery = { results: Array<Pick<ISearchResult, 'id' | 'description' | 'type'>> };

export type ITeamOverviewFragment = (
  Pick<ITeamOverview, 'id' | 'name' | 'target' | 'isPrivate' | 'memberCount'>
  & { membership?: Maybe<Pick<ITeamMembership, 'isAdmin' | 'status'>>, event: Pick<IEvent, 'id' | 'name'> }
);

export type ITeamDetailsFragment = (
  Pick<ITeam, 'id' | 'name' | 'target' | 'isPrivate'>
  & { event: Pick<IEvent, 'id' | 'name'>, membership?: Maybe<Pick<ITeamMembership, 'status' | 'isAdmin'>>, settings?: Maybe<Pick<ITeamSettings, 'code' | 'remainingEntries' | 'entries' | 'inviteUrl' | 'approvalRequired'>> }
);

export type ITeamMemberFragment = Pick<ITeamMember, 'id' | 'isAdmin' | 'firstName' | 'lastName' | 'status' | 'username' | 'purchased'>;

export type IGetTeamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetTeamQuery = { team: ITeamDetailsFragment };

export type IGetTeamsQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type IGetTeamsQuery = { teams: Array<ITeamOverviewFragment> };

export type IGetTeamMembersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetTeamMembersQuery = { members: Array<ITeamMemberFragment> };

export type IGetTeamByCodeQueryVariables = Exact<{
  code: Scalars['ID'];
}>;


export type IGetTeamByCodeQuery = { team: (
    Pick<IJoinTeam, 'id' | 'name' | 'status'>
    & { event: Pick<IEvent, 'id' | 'name'> }
  ) };

export type IGetTeamRequestQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['ID']>;
}>;


export type IGetTeamRequestQuery = { team: (
    Pick<IJoinTeam, 'id' | 'name' | 'status'>
    & { event: Pick<IEvent, 'id' | 'name'> }
  ) };

export type IGetUserTeamsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetUserTeamsQuery = { teams: Array<ITeamDetailsFragment> };

export type ISearchTeamsQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type ISearchTeamsQuery = { teams: Array<ITeamOverviewFragment> };

export type IUserProfileFragment = (
  Pick<IUserProfile, 'id' | 'email' | 'firstName' | 'lastName' | 'username' | 'isComplete'>
  & { providers: Array<Pick<IUserProvider, 'provider' | 'expired'>> }
);

export type IUserDetailsFragment = Pick<IUser, 'id' | 'email' | 'firstName' | 'lastName' | 'username'>;

export type IUserProviderFragment = Pick<IUserProvider, 'providerId' | 'disabled' | 'provider' | 'expired' | 'createdAt' | 'updatedAt'>;

export type IGetUsersQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type IGetUsersQuery = { users: Array<IUserDetailsFragment> };

export type IGetUserProvidersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetUserProvidersQuery = { providers: Array<IUserProviderFragment> };

export type IGetUserDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IGetUserDetailsQuery = { user: IUserDetailsFragment };

export type IGetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetProfileQuery = { profile: IUserProfileFragment };

export const AddressDetailsFragmentDoc = gql`
    fragment AddressDetails on Address {
  id
  address1
  address2
  city
  company
  country
  county
  email
  firstName
  lastName
  postcode
  type
}
    `;
export const ActivityDetailsFragmentFragmentDoc = gql`
    fragment ActivityDetailsFragment on Activity {
  id
  description
  distance
  start
  end
  type
  source
  manual
  createdAt
}
    `;
export const EventDetailsFragmentDoc = gql`
    fragment EventDetails on Event {
  id
  name
  description
  type
  start
  end
  groups {
    id
    name
    description
    target
  }
  settings {
    marketingId
    thumbnail
    header
    activities
    timebox {
      distance
      duration
    }
    distances
    mandatoryEvidence
  }
  entry {
    target
    team {
      id
      name
      target
      membersCount
      membership {
        status
        isAdmin
      }
    }
  }
}
    `;
export const EventActivityFragmentDoc = gql`
    fragment EventActivity on EventActivity {
  id
  start
  end
  distance
  type
}
    `;
export const ActivityStatisticsFragmentDoc = gql`
    fragment ActivityStatistics on ActivityStatistics {
  total
  totalDistance
  totalSeconds
  averageDistance
  averageSeconds
  longest
}
    `;
export const EventStatisticsFragmentDoc = gql`
    fragment EventStatistics on EventStatistics {
  activities {
    name
    total
    totalDistance
    averageDistance
    totalSeconds
    averageSeconds
    longest
  }
}
    `;
export const MessageDetailsFragmentDoc = gql`
    fragment MessageDetails on Message {
  id
  subject
  type
  sent
  text
}
    `;
export const TeamOverviewFragmentDoc = gql`
    fragment TeamOverview on TeamOverview {
  id
  name
  target
  isPrivate
  membership {
    isAdmin
    status
  }
  memberCount
  event {
    id
    name
  }
}
    `;
export const TeamDetailsFragmentDoc = gql`
    fragment TeamDetails on Team {
  id
  name
  target
  isPrivate
  event {
    id
    name
  }
  membership {
    status
    isAdmin
  }
  settings {
    code
    remainingEntries
    entries
    inviteUrl
    approvalRequired
  }
}
    `;
export const TeamMemberFragmentDoc = gql`
    fragment TeamMember on TeamMember {
  id
  isAdmin
  firstName
  lastName
  status
  username
  purchased
}
    `;
export const UserProfileFragmentDoc = gql`
    fragment UserProfile on UserProfile {
  id
  email
  firstName
  lastName
  username
  isComplete
  providers {
    provider
    expired
  }
}
    `;
export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on User {
  id
  email
  firstName
  lastName
  username
}
    `;
export const UserProviderFragmentDoc = gql`
    fragment UserProvider on UserProvider {
  providerId
  disabled
  provider
  expired
  createdAt
  updatedAt
}
    `;
export const CreateActivityDocument = gql`
    mutation CreateActivity($input: CreateActivityInput!) {
  activity: createActivity(input: $input) {
    ...ActivityDetailsFragment
  }
}
    ${ActivityDetailsFragmentFragmentDoc}`;
export type ICreateActivityMutationFn = Apollo.MutationFunction<ICreateActivityMutation, ICreateActivityMutationVariables>;

/**
 * __useCreateActivityMutation__
 *
 * To run a mutation, you first call `useCreateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityMutation, { data, loading, error }] = useCreateActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActivityMutation(baseOptions?: Apollo.MutationHookOptions<ICreateActivityMutation, ICreateActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateActivityMutation, ICreateActivityMutationVariables>(CreateActivityDocument, options);
      }
export type CreateActivityMutationHookResult = ReturnType<typeof useCreateActivityMutation>;
export type CreateActivityMutationResult = Apollo.MutationResult<ICreateActivityMutation>;
export type CreateActivityMutationOptions = Apollo.BaseMutationOptions<ICreateActivityMutation, ICreateActivityMutationVariables>;
export const UpdateAddressDocument = gql`
    mutation UpdateAddress($id: ID!, $address: UpdateAddressInput!) {
  updateAddress(id: $id, address: $address) {
    ...AddressDetails
  }
}
    ${AddressDetailsFragmentDoc}`;
export type IUpdateAddressMutationFn = Apollo.MutationFunction<IUpdateAddressMutation, IUpdateAddressMutationVariables>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useUpdateAddressMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateAddressMutation, IUpdateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateAddressMutation, IUpdateAddressMutationVariables>(UpdateAddressDocument, options);
      }
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export type UpdateAddressMutationResult = Apollo.MutationResult<IUpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<IUpdateAddressMutation, IUpdateAddressMutationVariables>;
export const GetAddressDocument = gql`
    query GetAddress($id: ID!) {
  address(id: $id) {
    ...AddressDetails
  }
}
    ${AddressDetailsFragmentDoc}`;

/**
 * __useGetAddressQuery__
 *
 * To run a query within a React component, call `useGetAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAddressQuery(baseOptions: Apollo.QueryHookOptions<IGetAddressQuery, IGetAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetAddressQuery, IGetAddressQueryVariables>(GetAddressDocument, options);
      }
export function useGetAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetAddressQuery, IGetAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetAddressQuery, IGetAddressQueryVariables>(GetAddressDocument, options);
        }
export type GetAddressQueryHookResult = ReturnType<typeof useGetAddressQuery>;
export type GetAddressLazyQueryHookResult = ReturnType<typeof useGetAddressLazyQuery>;
export type GetAddressQueryResult = Apollo.QueryResult<IGetAddressQuery, IGetAddressQueryVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($input: CreateEventInput!) {
  event: _createEvent(input: $input) {
    ...EventDetails
  }
}
    ${EventDetailsFragmentDoc}`;
export type ICreateEventMutationFn = Apollo.MutationFunction<ICreateEventMutation, ICreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<ICreateEventMutation, ICreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateEventMutation, ICreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<ICreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<ICreateEventMutation, ICreateEventMutationVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($input: UpdateEventInput!) {
  event: _updateEvent(input: $input) {
    ...EventDetails
  }
}
    ${EventDetailsFragmentDoc}`;
export type IUpdateEventMutationFn = Apollo.MutationFunction<IUpdateEventMutation, IUpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateEventMutation, IUpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateEventMutation, IUpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<IUpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<IUpdateEventMutation, IUpdateEventMutationVariables>;
export const GenerateResultsDocument = gql`
    mutation GenerateResults($id: ID!) {
  result: generateResults(id: $id)
}
    `;
export type IGenerateResultsMutationFn = Apollo.MutationFunction<IGenerateResultsMutation, IGenerateResultsMutationVariables>;

/**
 * __useGenerateResultsMutation__
 *
 * To run a mutation, you first call `useGenerateResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateResultsMutation, { data, loading, error }] = useGenerateResultsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGenerateResultsMutation(baseOptions?: Apollo.MutationHookOptions<IGenerateResultsMutation, IGenerateResultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IGenerateResultsMutation, IGenerateResultsMutationVariables>(GenerateResultsDocument, options);
      }
export type GenerateResultsMutationHookResult = ReturnType<typeof useGenerateResultsMutation>;
export type GenerateResultsMutationResult = Apollo.MutationResult<IGenerateResultsMutation>;
export type GenerateResultsMutationOptions = Apollo.BaseMutationOptions<IGenerateResultsMutation, IGenerateResultsMutationVariables>;
export const UploadImageDocument = gql`
    mutation UploadImage($file: Upload!) {
  url: uploadImage(file: $file)
}
    `;
export type IUploadImageMutationFn = Apollo.MutationFunction<IUploadImageMutation, IUploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<IUploadImageMutation, IUploadImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUploadImageMutation, IUploadImageMutationVariables>(UploadImageDocument, options);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<IUploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<IUploadImageMutation, IUploadImageMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($id: ID!, $input: UpdateTeamInput!) {
  team: updateTeam(id: $id, input: $input) {
    ...TeamDetails
  }
}
    ${TeamDetailsFragmentDoc}`;
export type IUpdateTeamMutationFn = Apollo.MutationFunction<IUpdateTeamMutation, IUpdateTeamMutationVariables>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateTeamMutation, IUpdateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateTeamMutation, IUpdateTeamMutationVariables>(UpdateTeamDocument, options);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<IUpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<IUpdateTeamMutation, IUpdateTeamMutationVariables>;
export const JoinTeamDocument = gql`
    mutation JoinTeam($id: ID, $code: ID) {
  result: joinTeam(id: $id, code: $code)
}
    `;
export type IJoinTeamMutationFn = Apollo.MutationFunction<IJoinTeamMutation, IJoinTeamMutationVariables>;

/**
 * __useJoinTeamMutation__
 *
 * To run a mutation, you first call `useJoinTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinTeamMutation, { data, loading, error }] = useJoinTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useJoinTeamMutation(baseOptions?: Apollo.MutationHookOptions<IJoinTeamMutation, IJoinTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IJoinTeamMutation, IJoinTeamMutationVariables>(JoinTeamDocument, options);
      }
export type JoinTeamMutationHookResult = ReturnType<typeof useJoinTeamMutation>;
export type JoinTeamMutationResult = Apollo.MutationResult<IJoinTeamMutation>;
export type JoinTeamMutationOptions = Apollo.BaseMutationOptions<IJoinTeamMutation, IJoinTeamMutationVariables>;
export const AcceptMemberDocument = gql`
    mutation AcceptMember($id: ID!, $memberId: ID!) {
  member: acceptMember(id: $id, memberId: $memberId) {
    id
    isAdmin
    firstName
    lastName
    status
  }
}
    `;
export type IAcceptMemberMutationFn = Apollo.MutationFunction<IAcceptMemberMutation, IAcceptMemberMutationVariables>;

/**
 * __useAcceptMemberMutation__
 *
 * To run a mutation, you first call `useAcceptMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptMemberMutation, { data, loading, error }] = useAcceptMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useAcceptMemberMutation(baseOptions?: Apollo.MutationHookOptions<IAcceptMemberMutation, IAcceptMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAcceptMemberMutation, IAcceptMemberMutationVariables>(AcceptMemberDocument, options);
      }
export type AcceptMemberMutationHookResult = ReturnType<typeof useAcceptMemberMutation>;
export type AcceptMemberMutationResult = Apollo.MutationResult<IAcceptMemberMutation>;
export type AcceptMemberMutationOptions = Apollo.BaseMutationOptions<IAcceptMemberMutation, IAcceptMemberMutationVariables>;
export const RejectMemberDocument = gql`
    mutation RejectMember($id: ID!, $memberId: ID!) {
  result: rejectMember(id: $id, memberId: $memberId)
}
    `;
export type IRejectMemberMutationFn = Apollo.MutationFunction<IRejectMemberMutation, IRejectMemberMutationVariables>;

/**
 * __useRejectMemberMutation__
 *
 * To run a mutation, you first call `useRejectMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectMemberMutation, { data, loading, error }] = useRejectMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useRejectMemberMutation(baseOptions?: Apollo.MutationHookOptions<IRejectMemberMutation, IRejectMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRejectMemberMutation, IRejectMemberMutationVariables>(RejectMemberDocument, options);
      }
export type RejectMemberMutationHookResult = ReturnType<typeof useRejectMemberMutation>;
export type RejectMemberMutationResult = Apollo.MutationResult<IRejectMemberMutation>;
export type RejectMemberMutationOptions = Apollo.BaseMutationOptions<IRejectMemberMutation, IRejectMemberMutationVariables>;
export const DeleteTeamMemberDocument = gql`
    mutation DeleteTeamMember($userId: ID!, $teamId: ID!) {
  result: _deleteTeamMember(userId: $userId, teamId: $teamId)
}
    `;
export type IDeleteTeamMemberMutationFn = Apollo.MutationFunction<IDeleteTeamMemberMutation, IDeleteTeamMemberMutationVariables>;

/**
 * __useDeleteTeamMemberMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMemberMutation, { data, loading, error }] = useDeleteTeamMemberMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<IDeleteTeamMemberMutation, IDeleteTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IDeleteTeamMemberMutation, IDeleteTeamMemberMutationVariables>(DeleteTeamMemberDocument, options);
      }
export type DeleteTeamMemberMutationHookResult = ReturnType<typeof useDeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationResult = Apollo.MutationResult<IDeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationOptions = Apollo.BaseMutationOptions<IDeleteTeamMemberMutation, IDeleteTeamMemberMutationVariables>;
export const CreateAccountDocument = gql`
    mutation CreateAccount($input: CreateAccountInput!) {
  token: createAccount(input: $input) {
    accessToken
    expiresIn
    idToken
  }
}
    `;
export type ICreateAccountMutationFn = Apollo.MutationFunction<ICreateAccountMutation, ICreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<ICreateAccountMutation, ICreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateAccountMutation, ICreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<ICreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<ICreateAccountMutation, ICreateAccountMutationVariables>;
export const LoginDocument = gql`
    mutation Login($id: ID!) {
  token: _login(id: $id) {
    accessToken
    expiresIn
    idToken
  }
}
    `;
export type ILoginMutationFn = Apollo.MutationFunction<ILoginMutation, ILoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<ILoginMutation, ILoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ILoginMutation, ILoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<ILoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<ILoginMutation, ILoginMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($profile: UpdateProfileInput!) {
  updateProfile(profile: $profile) {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;
export type IUpdateProfileMutationFn = Apollo.MutationFunction<IUpdateProfileMutation, IUpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateProfileMutation, IUpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateProfileMutation, IUpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<IUpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<IUpdateProfileMutation, IUpdateProfileMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: String!) {
  result: resetPassword(email: $email)
}
    `;
export type IResetPasswordMutationFn = Apollo.MutationFunction<IResetPasswordMutation, IResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<IResetPasswordMutation, IResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IResetPasswordMutation, IResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<IResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<IResetPasswordMutation, IResetPasswordMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($input: UpdatePasswordInput!) {
  result: updatePassword(input: $input)
}
    `;
export type IUpdatePasswordMutationFn = Apollo.MutationFunction<IUpdatePasswordMutation, IUpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<IUpdatePasswordMutation, IUpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdatePasswordMutation, IUpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<IUpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<IUpdatePasswordMutation, IUpdatePasswordMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken {
  token: refreshToken {
    accessToken
    expiresIn
    idToken
  }
}
    `;
export type IRefreshTokenMutationFn = Apollo.MutationFunction<IRefreshTokenMutation, IRefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<IRefreshTokenMutation, IRefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRefreshTokenMutation, IRefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<IRefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<IRefreshTokenMutation, IRefreshTokenMutationVariables>;
export const GetActivitiesDocument = gql`
    query GetActivities($from: DateTime, $to: DateTime) {
  activities(from: $from, to: $to) {
    ...ActivityDetailsFragment
  }
}
    ${ActivityDetailsFragmentFragmentDoc}`;

/**
 * __useGetActivitiesQuery__
 *
 * To run a query within a React component, call `useGetActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<IGetActivitiesQuery, IGetActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetActivitiesQuery, IGetActivitiesQueryVariables>(GetActivitiesDocument, options);
      }
export function useGetActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetActivitiesQuery, IGetActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetActivitiesQuery, IGetActivitiesQueryVariables>(GetActivitiesDocument, options);
        }
export type GetActivitiesQueryHookResult = ReturnType<typeof useGetActivitiesQuery>;
export type GetActivitiesLazyQueryHookResult = ReturnType<typeof useGetActivitiesLazyQuery>;
export type GetActivitiesQueryResult = Apollo.QueryResult<IGetActivitiesQuery, IGetActivitiesQueryVariables>;
export const GetActivityDocument = gql`
    query GetActivity($id: ID!) {
  activity(id: $id) {
    ...ActivityDetailsFragment
  }
}
    ${ActivityDetailsFragmentFragmentDoc}`;

/**
 * __useGetActivityQuery__
 *
 * To run a query within a React component, call `useGetActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivityQuery(baseOptions: Apollo.QueryHookOptions<IGetActivityQuery, IGetActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetActivityQuery, IGetActivityQueryVariables>(GetActivityDocument, options);
      }
export function useGetActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetActivityQuery, IGetActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetActivityQuery, IGetActivityQueryVariables>(GetActivityDocument, options);
        }
export type GetActivityQueryHookResult = ReturnType<typeof useGetActivityQuery>;
export type GetActivityLazyQueryHookResult = ReturnType<typeof useGetActivityLazyQuery>;
export type GetActivityQueryResult = Apollo.QueryResult<IGetActivityQuery, IGetActivityQueryVariables>;
export const GetUserActivitiesDocument = gql`
    query GetUserActivities($id: ID!) {
  activities: _userActivities(id: $id) {
    ...ActivityDetailsFragment
  }
}
    ${ActivityDetailsFragmentFragmentDoc}`;

/**
 * __useGetUserActivitiesQuery__
 *
 * To run a query within a React component, call `useGetUserActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserActivitiesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserActivitiesQuery(baseOptions: Apollo.QueryHookOptions<IGetUserActivitiesQuery, IGetUserActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserActivitiesQuery, IGetUserActivitiesQueryVariables>(GetUserActivitiesDocument, options);
      }
export function useGetUserActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserActivitiesQuery, IGetUserActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserActivitiesQuery, IGetUserActivitiesQueryVariables>(GetUserActivitiesDocument, options);
        }
export type GetUserActivitiesQueryHookResult = ReturnType<typeof useGetUserActivitiesQuery>;
export type GetUserActivitiesLazyQueryHookResult = ReturnType<typeof useGetUserActivitiesLazyQuery>;
export type GetUserActivitiesQueryResult = Apollo.QueryResult<IGetUserActivitiesQuery, IGetUserActivitiesQueryVariables>;
export const GetAddressesDocument = gql`
    query GetAddresses {
  addresses {
    ...AddressDetails
  }
}
    ${AddressDetailsFragmentDoc}`;

/**
 * __useGetAddressesQuery__
 *
 * To run a query within a React component, call `useGetAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAddressesQuery(baseOptions?: Apollo.QueryHookOptions<IGetAddressesQuery, IGetAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetAddressesQuery, IGetAddressesQueryVariables>(GetAddressesDocument, options);
      }
export function useGetAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetAddressesQuery, IGetAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetAddressesQuery, IGetAddressesQueryVariables>(GetAddressesDocument, options);
        }
export type GetAddressesQueryHookResult = ReturnType<typeof useGetAddressesQuery>;
export type GetAddressesLazyQueryHookResult = ReturnType<typeof useGetAddressesLazyQuery>;
export type GetAddressesQueryResult = Apollo.QueryResult<IGetAddressesQuery, IGetAddressesQueryVariables>;
export const GetUserAddressesDocument = gql`
    query GetUserAddresses($id: ID!) {
  addresses: _userAddresses(id: $id) {
    ...AddressDetails
  }
}
    ${AddressDetailsFragmentDoc}`;

/**
 * __useGetUserAddressesQuery__
 *
 * To run a query within a React component, call `useGetUserAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAddressesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserAddressesQuery(baseOptions: Apollo.QueryHookOptions<IGetUserAddressesQuery, IGetUserAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserAddressesQuery, IGetUserAddressesQueryVariables>(GetUserAddressesDocument, options);
      }
export function useGetUserAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserAddressesQuery, IGetUserAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserAddressesQuery, IGetUserAddressesQueryVariables>(GetUserAddressesDocument, options);
        }
export type GetUserAddressesQueryHookResult = ReturnType<typeof useGetUserAddressesQuery>;
export type GetUserAddressesLazyQueryHookResult = ReturnType<typeof useGetUserAddressesLazyQuery>;
export type GetUserAddressesQueryResult = Apollo.QueryResult<IGetUserAddressesQuery, IGetUserAddressesQueryVariables>;
export const GetEventDocument = gql`
    query GetEvent($id: ID!) {
  event: _event(id: $id) {
    ...EventDetails
  }
}
    ${EventDetailsFragmentDoc}`;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventQuery(baseOptions: Apollo.QueryHookOptions<IGetEventQuery, IGetEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetEventQuery, IGetEventQueryVariables>(GetEventDocument, options);
      }
export function useGetEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetEventQuery, IGetEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetEventQuery, IGetEventQueryVariables>(GetEventDocument, options);
        }
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventQueryResult = Apollo.QueryResult<IGetEventQuery, IGetEventQueryVariables>;
export const GetEventsDocument = gql`
    query GetEvents($filter: String, $take: Int, $skip: Int) {
  events: _events(filter: $filter, take: $take, skip: $skip) {
    ...EventDetails
  }
}
    ${EventDetailsFragmentDoc}`;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions?: Apollo.QueryHookOptions<IGetEventsQuery, IGetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetEventsQuery, IGetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetEventsQuery, IGetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetEventsQuery, IGetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<IGetEventsQuery, IGetEventsQueryVariables>;
export const GetEventStatisticsDocument = gql`
    query GetEventStatistics($id: ID!) {
  statistics: _eventStatistics(id: $id) {
    ...EventStatistics
  }
}
    ${EventStatisticsFragmentDoc}`;

/**
 * __useGetEventStatisticsQuery__
 *
 * To run a query within a React component, call `useGetEventStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventStatisticsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventStatisticsQuery(baseOptions: Apollo.QueryHookOptions<IGetEventStatisticsQuery, IGetEventStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetEventStatisticsQuery, IGetEventStatisticsQueryVariables>(GetEventStatisticsDocument, options);
      }
export function useGetEventStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetEventStatisticsQuery, IGetEventStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetEventStatisticsQuery, IGetEventStatisticsQueryVariables>(GetEventStatisticsDocument, options);
        }
export type GetEventStatisticsQueryHookResult = ReturnType<typeof useGetEventStatisticsQuery>;
export type GetEventStatisticsLazyQueryHookResult = ReturnType<typeof useGetEventStatisticsLazyQuery>;
export type GetEventStatisticsQueryResult = Apollo.QueryResult<IGetEventStatisticsQuery, IGetEventStatisticsQueryVariables>;
export const GetUserEventsDocument = gql`
    query GetUserEvents($id: ID!) {
  events: _userEvents(id: $id) {
    ...EventDetails
  }
}
    ${EventDetailsFragmentDoc}`;

/**
 * __useGetUserEventsQuery__
 *
 * To run a query within a React component, call `useGetUserEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserEventsQuery(baseOptions: Apollo.QueryHookOptions<IGetUserEventsQuery, IGetUserEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserEventsQuery, IGetUserEventsQueryVariables>(GetUserEventsDocument, options);
      }
export function useGetUserEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserEventsQuery, IGetUserEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserEventsQuery, IGetUserEventsQueryVariables>(GetUserEventsDocument, options);
        }
export type GetUserEventsQueryHookResult = ReturnType<typeof useGetUserEventsQuery>;
export type GetUserEventsLazyQueryHookResult = ReturnType<typeof useGetUserEventsLazyQuery>;
export type GetUserEventsQueryResult = Apollo.QueryResult<IGetUserEventsQuery, IGetUserEventsQueryVariables>;
export const GetEntriesByDateDocument = gql`
    query GetEntriesByDate {
  entries: _entriesByDate {
    date
    entries
    teams
  }
}
    `;

/**
 * __useGetEntriesByDateQuery__
 *
 * To run a query within a React component, call `useGetEntriesByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntriesByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntriesByDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEntriesByDateQuery(baseOptions?: Apollo.QueryHookOptions<IGetEntriesByDateQuery, IGetEntriesByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetEntriesByDateQuery, IGetEntriesByDateQueryVariables>(GetEntriesByDateDocument, options);
      }
export function useGetEntriesByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetEntriesByDateQuery, IGetEntriesByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetEntriesByDateQuery, IGetEntriesByDateQueryVariables>(GetEntriesByDateDocument, options);
        }
export type GetEntriesByDateQueryHookResult = ReturnType<typeof useGetEntriesByDateQuery>;
export type GetEntriesByDateLazyQueryHookResult = ReturnType<typeof useGetEntriesByDateLazyQuery>;
export type GetEntriesByDateQueryResult = Apollo.QueryResult<IGetEntriesByDateQuery, IGetEntriesByDateQueryVariables>;
export const GetEventMembersDocument = gql`
    query GetEventMembers($id: ID!) {
  members: _eventMembers(id: $id) {
    id
    user {
      id
      firstName
      lastName
      email
      username
    }
    distance
    target
    team {
      id
      name
      target
      membership {
        isAdmin
        status
      }
      group {
        id
        name
        target
      }
    }
    group {
      id
      name
      target
    }
  }
}
    `;

/**
 * __useGetEventMembersQuery__
 *
 * To run a query within a React component, call `useGetEventMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventMembersQuery(baseOptions: Apollo.QueryHookOptions<IGetEventMembersQuery, IGetEventMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetEventMembersQuery, IGetEventMembersQueryVariables>(GetEventMembersDocument, options);
      }
export function useGetEventMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetEventMembersQuery, IGetEventMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetEventMembersQuery, IGetEventMembersQueryVariables>(GetEventMembersDocument, options);
        }
export type GetEventMembersQueryHookResult = ReturnType<typeof useGetEventMembersQuery>;
export type GetEventMembersLazyQueryHookResult = ReturnType<typeof useGetEventMembersLazyQuery>;
export type GetEventMembersQueryResult = Apollo.QueryResult<IGetEventMembersQuery, IGetEventMembersQueryVariables>;
export const GetEventResultsDocument = gql`
    query GetEventResults($id: ID!) {
  results: getEventResults(id: $id)
}
    `;

/**
 * __useGetEventResultsQuery__
 *
 * To run a query within a React component, call `useGetEventResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventResultsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventResultsQuery(baseOptions: Apollo.QueryHookOptions<IGetEventResultsQuery, IGetEventResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetEventResultsQuery, IGetEventResultsQueryVariables>(GetEventResultsDocument, options);
      }
export function useGetEventResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetEventResultsQuery, IGetEventResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetEventResultsQuery, IGetEventResultsQueryVariables>(GetEventResultsDocument, options);
        }
export type GetEventResultsQueryHookResult = ReturnType<typeof useGetEventResultsQuery>;
export type GetEventResultsLazyQueryHookResult = ReturnType<typeof useGetEventResultsLazyQuery>;
export type GetEventResultsQueryResult = Apollo.QueryResult<IGetEventResultsQuery, IGetEventResultsQueryVariables>;
export const GetUserMessagesDocument = gql`
    query GetUserMessages($id: ID!) {
  messages: _userMessages(id: $id) {
    ...MessageDetails
  }
}
    ${MessageDetailsFragmentDoc}`;

/**
 * __useGetUserMessagesQuery__
 *
 * To run a query within a React component, call `useGetUserMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserMessagesQuery(baseOptions: Apollo.QueryHookOptions<IGetUserMessagesQuery, IGetUserMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserMessagesQuery, IGetUserMessagesQueryVariables>(GetUserMessagesDocument, options);
      }
export function useGetUserMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserMessagesQuery, IGetUserMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserMessagesQuery, IGetUserMessagesQueryVariables>(GetUserMessagesDocument, options);
        }
export type GetUserMessagesQueryHookResult = ReturnType<typeof useGetUserMessagesQuery>;
export type GetUserMessagesLazyQueryHookResult = ReturnType<typeof useGetUserMessagesLazyQuery>;
export type GetUserMessagesQueryResult = Apollo.QueryResult<IGetUserMessagesQuery, IGetUserMessagesQueryVariables>;
export const SearchDocument = gql`
    query Search($input: SearchInput!) {
  results: search(input: $input) {
    id
    description
    type
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<ISearchQuery, ISearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ISearchQuery, ISearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ISearchQuery, ISearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ISearchQuery, ISearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<ISearchQuery, ISearchQueryVariables>;
export const GetTeamDocument = gql`
    query GetTeam($id: ID!) {
  team: _team(id: $id) {
    ...TeamDetails
  }
}
    ${TeamDetailsFragmentDoc}`;

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamQuery(baseOptions: Apollo.QueryHookOptions<IGetTeamQuery, IGetTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetTeamQuery, IGetTeamQueryVariables>(GetTeamDocument, options);
      }
export function useGetTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetTeamQuery, IGetTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetTeamQuery, IGetTeamQueryVariables>(GetTeamDocument, options);
        }
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>;
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>;
export type GetTeamQueryResult = Apollo.QueryResult<IGetTeamQuery, IGetTeamQueryVariables>;
export const GetTeamsDocument = gql`
    query GetTeams($filter: String, $take: Int, $skip: Int) {
  teams: _teams(filter: $filter, take: $take, skip: $skip) {
    ...TeamOverview
  }
}
    ${TeamOverviewFragmentDoc}`;

/**
 * __useGetTeamsQuery__
 *
 * To run a query within a React component, call `useGetTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetTeamsQuery(baseOptions?: Apollo.QueryHookOptions<IGetTeamsQuery, IGetTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetTeamsQuery, IGetTeamsQueryVariables>(GetTeamsDocument, options);
      }
export function useGetTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetTeamsQuery, IGetTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetTeamsQuery, IGetTeamsQueryVariables>(GetTeamsDocument, options);
        }
export type GetTeamsQueryHookResult = ReturnType<typeof useGetTeamsQuery>;
export type GetTeamsLazyQueryHookResult = ReturnType<typeof useGetTeamsLazyQuery>;
export type GetTeamsQueryResult = Apollo.QueryResult<IGetTeamsQuery, IGetTeamsQueryVariables>;
export const GetTeamMembersDocument = gql`
    query GetTeamMembers($id: ID!) {
  members: _teamMembers(id: $id) {
    ...TeamMember
  }
}
    ${TeamMemberFragmentDoc}`;

/**
 * __useGetTeamMembersQuery__
 *
 * To run a query within a React component, call `useGetTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamMembersQuery(baseOptions: Apollo.QueryHookOptions<IGetTeamMembersQuery, IGetTeamMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetTeamMembersQuery, IGetTeamMembersQueryVariables>(GetTeamMembersDocument, options);
      }
export function useGetTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetTeamMembersQuery, IGetTeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetTeamMembersQuery, IGetTeamMembersQueryVariables>(GetTeamMembersDocument, options);
        }
export type GetTeamMembersQueryHookResult = ReturnType<typeof useGetTeamMembersQuery>;
export type GetTeamMembersLazyQueryHookResult = ReturnType<typeof useGetTeamMembersLazyQuery>;
export type GetTeamMembersQueryResult = Apollo.QueryResult<IGetTeamMembersQuery, IGetTeamMembersQueryVariables>;
export const GetTeamByCodeDocument = gql`
    query GetTeamByCode($code: ID!) {
  team: teamByCode(code: $code) {
    id
    name
    event {
      id
      name
    }
    status
  }
}
    `;

/**
 * __useGetTeamByCodeQuery__
 *
 * To run a query within a React component, call `useGetTeamByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetTeamByCodeQuery(baseOptions: Apollo.QueryHookOptions<IGetTeamByCodeQuery, IGetTeamByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetTeamByCodeQuery, IGetTeamByCodeQueryVariables>(GetTeamByCodeDocument, options);
      }
export function useGetTeamByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetTeamByCodeQuery, IGetTeamByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetTeamByCodeQuery, IGetTeamByCodeQueryVariables>(GetTeamByCodeDocument, options);
        }
export type GetTeamByCodeQueryHookResult = ReturnType<typeof useGetTeamByCodeQuery>;
export type GetTeamByCodeLazyQueryHookResult = ReturnType<typeof useGetTeamByCodeLazyQuery>;
export type GetTeamByCodeQueryResult = Apollo.QueryResult<IGetTeamByCodeQuery, IGetTeamByCodeQueryVariables>;
export const GetTeamRequestDocument = gql`
    query GetTeamRequest($id: ID, $code: ID) {
  team: teamRequest(id: $id, code: $code) {
    id
    name
    event {
      id
      name
    }
    status
  }
}
    `;

/**
 * __useGetTeamRequestQuery__
 *
 * To run a query within a React component, call `useGetTeamRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetTeamRequestQuery(baseOptions?: Apollo.QueryHookOptions<IGetTeamRequestQuery, IGetTeamRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetTeamRequestQuery, IGetTeamRequestQueryVariables>(GetTeamRequestDocument, options);
      }
export function useGetTeamRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetTeamRequestQuery, IGetTeamRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetTeamRequestQuery, IGetTeamRequestQueryVariables>(GetTeamRequestDocument, options);
        }
export type GetTeamRequestQueryHookResult = ReturnType<typeof useGetTeamRequestQuery>;
export type GetTeamRequestLazyQueryHookResult = ReturnType<typeof useGetTeamRequestLazyQuery>;
export type GetTeamRequestQueryResult = Apollo.QueryResult<IGetTeamRequestQuery, IGetTeamRequestQueryVariables>;
export const GetUserTeamsDocument = gql`
    query GetUserTeams($id: ID!) {
  teams: _userTeams(id: $id) {
    ...TeamDetails
  }
}
    ${TeamDetailsFragmentDoc}`;

/**
 * __useGetUserTeamsQuery__
 *
 * To run a query within a React component, call `useGetUserTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTeamsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserTeamsQuery(baseOptions: Apollo.QueryHookOptions<IGetUserTeamsQuery, IGetUserTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserTeamsQuery, IGetUserTeamsQueryVariables>(GetUserTeamsDocument, options);
      }
export function useGetUserTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserTeamsQuery, IGetUserTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserTeamsQuery, IGetUserTeamsQueryVariables>(GetUserTeamsDocument, options);
        }
export type GetUserTeamsQueryHookResult = ReturnType<typeof useGetUserTeamsQuery>;
export type GetUserTeamsLazyQueryHookResult = ReturnType<typeof useGetUserTeamsLazyQuery>;
export type GetUserTeamsQueryResult = Apollo.QueryResult<IGetUserTeamsQuery, IGetUserTeamsQueryVariables>;
export const SearchTeamsDocument = gql`
    query SearchTeams($query: String!) {
  teams: findTeams(query: $query) {
    ...TeamOverview
  }
}
    ${TeamOverviewFragmentDoc}`;

/**
 * __useSearchTeamsQuery__
 *
 * To run a query within a React component, call `useSearchTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTeamsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchTeamsQuery(baseOptions: Apollo.QueryHookOptions<ISearchTeamsQuery, ISearchTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ISearchTeamsQuery, ISearchTeamsQueryVariables>(SearchTeamsDocument, options);
      }
export function useSearchTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ISearchTeamsQuery, ISearchTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ISearchTeamsQuery, ISearchTeamsQueryVariables>(SearchTeamsDocument, options);
        }
export type SearchTeamsQueryHookResult = ReturnType<typeof useSearchTeamsQuery>;
export type SearchTeamsLazyQueryHookResult = ReturnType<typeof useSearchTeamsLazyQuery>;
export type SearchTeamsQueryResult = Apollo.QueryResult<ISearchTeamsQuery, ISearchTeamsQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($filter: String, $take: Int, $skip: Int) {
  users: _users(filter: $filter, take: $take, skip: $skip) {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<IGetUsersQuery, IGetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUsersQuery, IGetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUsersQuery, IGetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUsersQuery, IGetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<IGetUsersQuery, IGetUsersQueryVariables>;
export const GetUserProvidersDocument = gql`
    query GetUserProviders($id: ID!) {
  providers: _userProviders(id: $id) {
    ...UserProvider
  }
}
    ${UserProviderFragmentDoc}`;

/**
 * __useGetUserProvidersQuery__
 *
 * To run a query within a React component, call `useGetUserProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProvidersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserProvidersQuery(baseOptions: Apollo.QueryHookOptions<IGetUserProvidersQuery, IGetUserProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserProvidersQuery, IGetUserProvidersQueryVariables>(GetUserProvidersDocument, options);
      }
export function useGetUserProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserProvidersQuery, IGetUserProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserProvidersQuery, IGetUserProvidersQueryVariables>(GetUserProvidersDocument, options);
        }
export type GetUserProvidersQueryHookResult = ReturnType<typeof useGetUserProvidersQuery>;
export type GetUserProvidersLazyQueryHookResult = ReturnType<typeof useGetUserProvidersLazyQuery>;
export type GetUserProvidersQueryResult = Apollo.QueryResult<IGetUserProvidersQuery, IGetUserProvidersQueryVariables>;
export const GetUserDetailsDocument = gql`
    query GetUserDetails($id: ID!) {
  user: _user(id: $id) {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDetailsQuery(baseOptions: Apollo.QueryHookOptions<IGetUserDetailsQuery, IGetUserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserDetailsQuery, IGetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
      }
export function useGetUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserDetailsQuery, IGetUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserDetailsQuery, IGetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
        }
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsQueryResult = Apollo.QueryResult<IGetUserDetailsQuery, IGetUserDetailsQueryVariables>;
export const GetProfileDocument = gql`
    query GetProfile {
  profile {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions?: Apollo.QueryHookOptions<IGetProfileQuery, IGetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetProfileQuery, IGetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetProfileQuery, IGetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetProfileQuery, IGetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<IGetProfileQuery, IGetProfileQueryVariables>;