import React, { useMemo } from 'react';
import qs from 'qs';
import { Redirect as RouterRedirect } from 'react-router-dom';

interface Props {
  to: string;
  query?: Record<string, string | number | undefined>;
}

function Redirect({ to, query }: Props) {
  const path = useMemo(() => {
    if (!query) {
      return to;
    }

    const obj = qs.stringify(query, {
      skipNulls: true,
    });

    return `${to}?${obj}`;
  }, [to, query]);

  return <RouterRedirect to={path} />;
}

export function createRedirect() {
  const redirect = `${window.location.pathname}${window.location.search}`;

  if (redirect === '/') {
    return undefined;
  }

  return redirect;
}

export function retrieveRedirect() {
  const { search } = window.location;

  const query = qs.parse(search, { ignoreQueryPrefix: true });

  return query.redirect as string;
}

export default Redirect;
