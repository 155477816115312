import React, { memo } from 'react';
import { CSVLink } from 'react-csv';
import Button from '../Button/Button';

interface Props {
  data: any;
  name: string;
  headers: string[];
}

function ExportButton({ data, name, headers }: Props) {
  return (
    <CSVLink data={data} filename={name} headers={headers}>
      <Button variant="primary">Export</Button>
    </CSVLink>
  );
}

export default memo(ExportButton);
