import React, { useMemo } from 'react';
import { TextInputFormik } from 'components/Form/TextInput/TextInput';
import { ICreateEventInput } from 'graphql/types';
import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns';
import { Form, Formik } from 'formik';
import Button from 'components/Buttons/Button/Button';
import { EventCreationProps } from '../EventTypes';
import EventFields from '../Shared/EventFields';

export default function TimeboxedEventCreate({
  onSave,
  beforeForm,
}: EventCreationProps) {
  const initialValues: ICreateEventInput = useMemo(() => {
    const start = addMonths(startOfMonth(new Date()), 1);
    const end = endOfMonth(start);

    return {
      name: '',
      description: {
        en: '',
      },
      type: 'TIMEBOXED',
      start: format(start, "yyyy-MM-dd'T'HH:mm"),
      end: format(end, "yyyy-MM-dd'T'HH:mm"),
      config: {
        marketingId: '',
        header: '',
        thumbnail: '',
        activities: [],
        timebox: {
          duration: 24,
          distance: 42195, // Marathon default
        },
        mandatoryEvidence: false,
      },
      groups: [],
    };
  }, []);

  return (
    <Formik<ICreateEventInput> initialValues={initialValues} onSubmit={onSave}>
      {({ values, setValues }) => (
        <Form className="Form">
          <div className="Page-title">
            Create event
            <Button variant="primary">Save</Button>
          </div>
          <hr />

          {beforeForm && beforeForm}

          <EventFields />

          <>
            <h3>Timeboxed event settings</h3>
            <div className="Form-group">
              <TextInputFormik
                label="Timebox duration (hours)"
                name="config.timebox.duration"
                type="number"
                required
              />
              <TextInputFormik
                label="Timeboxed distance (metres)"
                name="config.timebox.distance"
                type="number"
                required
              />
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
}
