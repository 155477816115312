import React, { useState } from 'react';

import './FileInput.scss';
import { useField } from 'formik';
import { useUploadImageMutation } from 'graphql/types';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  setLoading?(loading: boolean): void;
}

function FileInput({ label, name, ...inputProps }: Props) {
  const [{ value, ...field }, { error, touched }, { setValue }] = useField(
    name
  );
  const [loading, setLoading] = useState(false);
  const [upload] = useUploadImageMutation();

  async function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.item(0);
    console.log(file);
    if (!file) {
      return;
    }

    setLoading(true);
    inputProps.setLoading?.(true);

    try {
      const { data } = await upload({
        variables: { file },
      });

      if (data?.url) {
        setValue(data?.url);
      }
    } catch (e) {
    } finally {
      setLoading(false);
      inputProps.setLoading?.(false);
    }
  }

  return (
    <label className="Input FileInput">
      <span className="Input-label">
        {label}{' '}
        {error && touched && <span className="Input-error">{error}</span>}
      </span>
      <div className="Input-container">
        <input type="file" {...field} onChange={onChange} />
        {loading && <span>Saving...</span>}
        {value && (
          <div className="FileInput-preview">
            <img src={value} alt="preview" />
          </div>
        )}
      </div>
    </label>
  );
}

export default FileInput;
