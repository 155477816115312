import React from 'react';

import Link from 'components/Router/Link';
import Distance from 'components/Distance/Distance';
import { useGetUserEventsQuery } from '../../../../graphql/types';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader/Loader';
import Table from '../../../../components/Table/Table';

function UserEvents() {
  const columns = ['Name', 'Target', 'Entry Type'];
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetUserEventsQuery({ variables: { id } });

  const events = data?.events ?? [];

  if (loading) {
    return <Loader />;
  }

  return (
    <Table columns={columns}>
      {events.map((event) => (
        <tr key={event.id}>
          <td>
            <Link to={`/users/${id}/events/${event.id}`}>{event.name}</Link>
          </td>
          <td>
            <div>
              <Distance metres={event.entry?.target} digits={0} />
            </div>
          </td>
          <td>
            <div>{event.entry && event.entry.team ? 'Team' : 'Individual'}</div>
          </td>
        </tr>
      ))}
    </Table>
  );
}

export default UserEvents;
