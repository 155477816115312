import { useLocation } from 'react-router-dom';
import qs from 'qs';

export function useSearch<T = any>(): T {
  const { search } = useLocation();

  return qs.parse(search, { ignoreQueryPrefix: true }) as any;
}

export function useSearchStatic<T = any>(): T {
  return qs.parse(window.location.search, { ignoreQueryPrefix: true }) as any;
}
