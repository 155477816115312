import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CreateTeamPage from './CreateTeamPage/CreateTeamPage';
import TeamPage from './TeamPage/TeamPage';
import TeamsPage from './TeamsPage/TeamsPage';

function TeamsRoute() {
  return (
    <Switch>
      <Route path="/teams" exact component={TeamsPage} />
      <Route path="/teams/create" exact component={CreateTeamPage} />
      <Route path="/teams/:id" component={TeamPage} />
    </Switch>
  );
}

export default TeamsRoute;
