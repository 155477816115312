import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UsersPage from './UsersPage/UsersPage';
import UserPage from './UserPage/UserPage';

function UsersRoute() {
  return (
    <Switch>
      <Route path="/users" exact component={UsersPage} />
      <Route path="/users/:id" component={UserPage} />
    </Switch>
  );
}

export default UsersRoute;
