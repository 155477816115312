import Page from 'components/Page/Page';
import React, { useEffect, useMemo, useState } from 'react';

import './TeamsPage.scss';
import { IEventDetailsFragment, useGetEventsLazyQuery } from 'graphql/types';
import _ from 'lodash';
import Link from 'components/Router/Link';
import Table from '../../../components/Table/Table';
import LinkButton from 'components/Buttons/LinkButton/LinkButton';
import DateFormat from '../../../components/DateFormat/DateFormat';

function EventsPage() {
  const [getEvents, { data }] = useGetEventsLazyQuery();
  const [events, setEvents] = useState<IEventDetailsFragment[]>([]);

  const search = useMemo(() => {
    const throttle = _.debounce(async (filter: string) => {
      console.log({ filter });

      return getEvents({
        variables: { filter, take: 10 },
      });
    }, 250);

    return throttle;
  }, []);

  useEffect(() => {
    if (data?.events) {
      setEvents(data.events);
    }
  }, [data?.events]);

  useEffect(() => {
    search('');
  }, []);

  const columns = ['Name', 'Start', 'End'];

  return (
    <Page slug="Events">
      <div className="Page-title">
        Events
        <div>
          <LinkButton variant="primary" to="/events/create">
            Create an event
          </LinkButton>
        </div>
      </div>
      <div>
        <div className="Search">
          <label>
            <svg
              viewBox="0 0 16 16"
              fill="currentColor"
              height="16px"
              width="16px"
            >
              <path d="M6,12A6,6,0,1,1,12,6,6,6,0,0,1,6,12ZM6,1.54A4.46,4.46,0,1,0,10.45,6,4.46,4.46,0,0,0,6,1.54Z" />
              <path d="M15.2,16a.74.74,0,0,1-.53-.22L9.14,10.2A.75.75,0,0,1,10.2,9.14l5.53,5.53a.75.75,0,0,1,0,1.06A.74.74,0,0,1,15.2,16Z" />
            </svg>
          </label>
          <input
            onChange={(e) => search(e.target.value)}
            placeholder="Search by ID or name."
          />
        </div>
      </div>

      <Table columns={columns}>
        {events.map(({ id, name, start, end }) => (
          <tr key={id}>
            <td>
              <Link to={`/events/${id}`}>{name}</Link>
            </td>
            <td>
              <div>
                <DateFormat value={start} format="dd/MM/yyyy" />
              </div>
            </td>
            <td>
              <div>
                <DateFormat value={end} format="dd/MM/yyyy" />
              </div>
            </td>
          </tr>
        ))}
      </Table>
    </Page>
  );
}

export default EventsPage;
