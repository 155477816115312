import React from 'react';
import { IEventDetailsFragment } from '../graphql/types';

export const EventContext = React.createContext<IEventDetailsFragment>(
  undefined as any
);

export const useEvent = () => React.useContext(EventContext);

interface Props {
  event: IEventDetailsFragment;
}

function EventProvider({ event, children }: React.PropsWithChildren<Props>) {
  return (
    <EventContext.Provider value={event}>{children}</EventContext.Provider>
  );
}

export default EventProvider;
