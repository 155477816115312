import React from 'react';
import { IEventDetailsFragment } from 'graphql/types';
import DataSectionRow from '../../../components/DataSection/DataSectionRow';
import EventDetails from '../Shared/EventDetails';

interface Props {
  event: IEventDetailsFragment;
}

export default function TimeboxedEventDetails({ event }: Props) {
  return (
    <EventDetails event={event}>
      <DataSectionRow label="Timebox duration (hours)">
        {event.settings.timebox?.duration}
      </DataSectionRow>
      <DataSectionRow label="Timebox distance (metres)">
        {event.settings.timebox?.distance}
      </DataSectionRow>
    </EventDetails>
  );
}
