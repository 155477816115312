import React, { useMemo } from 'react';
import { ICreateEventInput } from 'graphql/types';
import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns';
import { Form, Formik } from 'formik';
import Button from 'components/Buttons/Button/Button';
import { EventEditProps } from '../EventTypes';
import EventFields from '../Shared/EventFields';

export default function DistanceEventEdit({
  event,
  onSave,
  beforeForm,
}: EventEditProps) {
  const initialValues = {
    id: event.id,
    name: event.name,
    description: event.description,
    type: event.type,
    // This is done instead of casting to a date to avoid timezone issues with the users browser. Not ideal
    start: event.start.substring(0, event.start.length - ':00.000Z'.length),
    end: event.end.substring(0, event.start.length - ':00.000Z'.length),
    config: {
      marketingId: event.settings.marketingId,
      thumbnail: event.settings.thumbnail,
      header: event.settings.header,
      activities: event.settings.activities,
      mandatoryEvidence: event.settings.mandatoryEvidence,
    },
    groups: event.groups.map((group) => ({
      id: group.id,
      name: group.name,
      description: group.description,
      target: group.target,
    })),
  };

  return (
    <Formik<ICreateEventInput> initialValues={initialValues} onSubmit={onSave}>
      {({ values, setValues }) => (
        <Form className="Form">
          <div className="Page-title">
            Edit {event.name}
            <Button variant="primary">Save</Button>
          </div>
          <hr />

          {beforeForm && beforeForm}

          <EventFields />
        </Form>
      )}
    </Formik>
  );
}
