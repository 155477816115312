import React from 'react';
import { useParams } from 'react-router-dom';

import {
  ITeamMemberFragment,
  useDeleteTeamMemberMutation,
  useGetTeamMembersQuery,
} from 'graphql/types';
import { orderBy } from 'lodash';
import Link from 'components/Router/Link';
import Loader from 'components/Loader/Loader';
import Table from 'components/Table/Table';
import YesNo from 'components/YesNo/YesNo';
import Bin from 'components/Icons/Bin';
import Button from 'components/Buttons/Button/Button';

function TeamMembers() {
  const columns = ['Name', 'Username', 'Admin', 'Status', 'Purchased', ''];
  const { id } = useParams<{ id: string }>();
  const { data, loading, refetch } = useGetTeamMembersQuery({
    variables: { id },
  });
  const [deleteMember] = useDeleteTeamMemberMutation();

  if (loading) {
    return <Loader />;
  }

  const members =
    orderBy(data?.members, (m) => `${m.firstName} ${m.lastName}`) ?? [];

  async function onDelete(member: ITeamMemberFragment) {
    // eslint-disable-next-line no-restricted-globals
    const result = confirm(
      `Are you sure you want to delete ${member.firstName}?`
    );

    if (!result) {
      return;
    }

    await deleteMember({
      variables: {
        teamId: id,
        userId: member.id,
      },
    });

    await refetch();
  }

  return (
    <Table
      columns={columns}
      template={`repeat(${columns.length - 1}, minmax(90px, auto)) min-content`}
    >
      {members.map((member) => (
        <tr key={member.id}>
          <td>
            <Link to={`/teams/${id}/members/${member.id}`}>
              {member.firstName} {member.lastName}
            </Link>
          </td>
          <td>
            <div>{member.username}</div>
          </td>
          <td>
            <div>
              <YesNo value={member.isAdmin} />
            </div>
          </td>
          <td>
            <div>{member.status}</div>
          </td>
          <td>
            <div>
              <YesNo value={member.purchased} />
            </div>
          </td>
          <td className="Text-right">
            <div>
              <Button className="Button-icon" onClick={() => onDelete(member)}>
                <div className="Button-icon-wrapper">
                  <Bin />
                </div>
              </Button>
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
}

export default TeamMembers;
