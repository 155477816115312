import React from 'react';
import { useGetEventMembersQuery } from 'graphql/types';
import { CSVLink } from 'react-csv';

import Loader from 'components/Loader/Loader';
import Table from 'components/Table/Table';
import Link from 'components/Router/Link';
import Distance from 'components/Distance/Distance';
import Button from 'components/Buttons/Button/Button';
import { useEvent } from '../../../../providers/EventProvider';

function EventMembers() {
  const { id, name } = useEvent();
  const { data, loading } = useGetEventMembersQuery({ variables: { id } });

  if (loading) {
    return <Loader />;
  }

  if (!data?.members) {
    return <Loader />;
  }

  const { members } = data;

  const columns = ['Name', 'Email', 'Target', 'Distance', 'Team', 'Group'];

  const headers = [
    'id',
    'firstName',
    'lastName',
    'email',
    'target',
    'team',
    'group',
  ];

  const csv = members.map((member) => {
    return {
      id: member.user.id,
      firstName: member.user.firstName,
      lastName: member.user.lastName,
      email: member.user.email,
      target: (member.target / 1000).toFixed(),
      team: member.team?.name,
      group: member.group?.name,
    };
  });

  return (
    <div className="EventMembers">
      <div className="EventMembers-header">
        <CSVLink
          data={csv}
          filename={`${name.toLowerCase().replace(/ /gim, '-')}-members.csv`}
          headers={headers}
        >
          <Button variant="primary">Export</Button>
        </CSVLink>
      </div>
      <Table columns={columns}>
        {members.map(({ id, user, target, distance, team, group }) => (
          <tr key={id}>
            <td>
              <Link to={`/users/${user.id}`}>
                {user.firstName} {user.lastName}
              </Link>
            </td>
            <td>
              <div>{user.email}</div>
            </td>
            <td>
              <div>
                <Distance metres={distance} />
              </div>
            </td>
            <td>
              <div>
                <Distance metres={target} />
              </div>
            </td>
            <td>{team && <Link to={`/teams/${team.id}`}>{team.name}</Link>}</td>
            <td>{group && <div>{group.name}</div>}</td>
          </tr>
        ))}
      </Table>
    </div>
  );
}

export default EventMembers;
