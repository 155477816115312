import React from 'react';
import { useField } from 'formik';

import './SelectInput.scss';

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  error?: string;
  touched?: boolean;
}

function SelectInput({
  label,
  error,
  touched,
  children,
  ...inputProps
}: Props) {
  return (
    <label className="Input">
      {label && (
        <div className="Input-label">
          {label}{' '}
          {inputProps.required && <span className="Input-required">*</span>}
          {error && touched && <span className="Input-error">{error}</span>}
        </div>
      )}
      <div className="Input-container">
        <select {...inputProps}>{children}</select>
      </div>
    </label>
  );
}

interface FormikInputProps extends Props {
  name: string;
}

export function SelectInputFormik({ label, ...inputProps }: FormikInputProps) {
  const [field, { error, touched }] = useField(inputProps);

  return (
    <SelectInput
      label={label}
      touched={touched}
      error={error}
      {...inputProps}
      {...field}
    />
  );
}

export default SelectInput;
