import Page from 'components/Page/Page';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  ICreateEventInput,
  IEventType,
  useCreateEventMutation,
} from 'graphql/types';
import { EVENT_TYPES } from 'types/event';

import SelectInput from 'components/Form/SelectInput/SelectInput';
import { getEventCreationForm } from 'components/Events/EventTypes';

import './CreateEventPage.scss';
import { useState } from 'react';

function CreateEventPage() {
  const { id } = useParams<{ id?: string }>();
  const [createEvent] = useCreateEventMutation();
  const { push } = useHistory();
  const [eventType, setEventType] = useState(EVENT_TYPES[0]);

  async function onSave(values: ICreateEventInput) {
    console.log(values);

    const { data } = await createEvent({
      variables: {
        input: {
          ...values,
          // This is done instead of casting to a date to avoid timezone issues with the users browser. Not ideal
          start: `${values.start}:00.000Z`,
          end: `${values.end}:00.000Z`,
        },
      },
    });

    if (data) {
      push(`/events/${data.event.id}`);
    }
  }

  const EventCreationForm = getEventCreationForm(eventType);

  return (
    <Page className="CreateEventPage" slug="Create Event">
      <EventCreationForm
        onSave={onSave}
        beforeForm={
          <SelectInput
            label="Event Type"
            name="type"
            required
            onChange={(e) => setEventType(e.target.value as IEventType)}
            value={eventType}
          >
            {EVENT_TYPES.map((type) => (
              <option value={type} key={type}>
                {type}
              </option>
            ))}
          </SelectInput>
        }
      />
    </Page>
  );
}

export default CreateEventPage;
