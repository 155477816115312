import { IActivityType } from '../graphql/types';

export const ACTIVITY_TYPES: IActivityType[] = [
  'RIDE',
  'RUN',
  'SWIM',
  'HIKE',
  'WALK',
  'GOLF',
  'ALPINE_SKI',
  'BACK_COUNTRY_SKI',
  'CANOEING',
  'CROSSFIT',
  'EBIKE_RIDE',
  'ELLIPTICAL',
  'HAND_CYCLE',
  'ICE_SKATE',
  'INLINE_SKATE',
  'KAYAKING',
  'KITESURF',
  'NORDIC_SKI',
  'ROCK_CLIMBING',
  'ROLLER_SKI',
  'ROWING',
  'SNOWBOARD',
  'SNOWSHOE',
  'SOCCER',
  'STAIR_STEPPER',
  'STANDUP_PADDLING',
  'SURFING',
  'VELOMOBILE',
  'VIRTUAL_RIDE',
  'VIRTUAL_RUN',
  'WEIGHT_TRAINING',
  'WHEELCHAIR',
  'WINDSURF',
  'WORKOUT',
  'YOGA',
  'STEPS',
];
