import React, { useState } from 'react';

export type IUnit = 'km' | 'mi';

export interface IPreferencesContext {
  unit: IUnit;
  setUnit(unit: IUnit): void;
}

export const PreferencesContext = React.createContext<IPreferencesContext>({
  unit: 'km',
  setUnit(unit: 'km' | 'mi'): void {},
});

export const usePreferences = () => React.useContext(PreferencesContext);

function PreferencesProvider({ children }: React.PropsWithChildren<unknown>) {
  const [unit, setUnit] = useState<IUnit>(() => localStorage['unit'] ?? 'km');

  return (
    <PreferencesContext.Provider
      value={{
        unit,
        setUnit(unit: IUnit): void {
          localStorage['unit'] = unit;
          setUnit(unit);
        },
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
}

export default PreferencesProvider;
