import React from 'react';

import { useGetUserMessagesQuery } from '../../../../graphql/types';
import { useParams } from 'react-router-dom';
import Link from '../../../../components/Router/Link';
import DateFormat from '../../../../components/DateFormat/DateFormat';
import Loader from '../../../../components/Loader/Loader';
import Table from '../../../../components/Table/Table';

function UserMessages() {
  const columns = ['Subject', 'Type', 'Sent'];
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetUserMessagesQuery({ variables: { id } });

  if (loading) {
    return <Loader />;
  }

  const messages = data?.messages ?? [];

  return (
    <Table columns={columns}>
      {messages.map((message) => (
        <tr key={message.id}>
          <td>
            <Link to={`/users/${id}/messages/${message.id}`}>
              {message.subject}
            </Link>
          </td>
          <td>
            <div>{message.type}</div>
          </td>
          <td>
            <div>
              <DateFormat value={message.sent} format="dd/MM/yyyy HH:mm" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
}

export default UserMessages;
