import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import './Button.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: string;
}

function Button({
  type,
  variant = 'secondary',
  className,
  children,
  ...buttonProps
}: PropsWithChildren<Props>) {
  const classNames = classnames('Button', className, {
    [`Button-${type}`]: type,
    [`Button-${variant}`]: variant,
  });

  return (
    <button className={classNames} type={type} {...buttonProps}>
      {children}
    </button>
  );
}

export default Button;
